import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import AlertsIcon from  './AlertsIcon'
import { epochToTimestamp } from '../libs/utils'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});


export default function DeviceAlerts({ Alerts }) {
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Severity</TableCell>
              <TableCell>Time</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Value</TableCell>
              <TableCell>Threshold</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
            (Alerts.length > 0) ?
              (
                Alerts.map(row => (
                  <TableRow key={row.id}>
                    <TableCell><AlertsIcon needsColour status={row.rule}/></TableCell>
                    <TableCell>{epochToTimestamp(row.startEpoch)}</TableCell>
                    <TableCell component="th" scope="row">
                      {row.resourceTemplateName}
                    </TableCell>
                    <TableCell>{row.alertValue}</TableCell>
                    <TableCell>{row.threshold}</TableCell>
                  </TableRow>
                ))
              )
              :
              (
                <TableRow>
                  <TableCell>
                    <Typography>No current alerts for this service</Typography>
                  </TableCell>
                </TableRow>
              )
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
