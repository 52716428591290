
import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
// import { useNavigation } from '@react-navigation/native';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { initDeviceSearch, resetDeviceSearch } from '../actions/devices';

import CircularIntegration from '../components/CircularIntegration'
import PageRefreshInput from '../components/PageRefreshInput'
import { InputLabel } from '@material-ui/core';
import { FormControl } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    textField: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(1),
      width: 1000
    },
    sticky: {
      position: '-webkit-sticky', /* Safari */
      // eslint-disable-next-line
      position: 'sticky',
      top: 0
    },
    label: {
      marginLeft: theme.spacing(2)
    },
    title: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      marginLeft: theme.spacing(2)
    },
    grid: {
      marginBottom: theme.spacing(1)
    }
  });

class ServiceSearchForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search_term: props.search_term || ""
    }
  }

  componentDidMount() {

  }

  async performSearch(event) {
    // console.log(event)
    event && event.preventDefault()
    this.setState({ loading_search: true })
    await this.props.initDeviceSearch(this.state.search_term)
    // await this.props.initRequestAlerts()
    this.props.history.push('/services/connectivity')
    this.setState({ loading_search: false })
  }

  resetSearch() {
    console.log(this.state.search_term)
    this.setState({ search_term: "", loading_search: true })
    console.log(this.state.search_term)
    // await this.props.initDeviceSearch("")
    this.props.resetDeviceSearch()
    this.setState({ loading_search: false })
  }

  updateSearchTerm(input_text) {
    this.setState({ search_term: input_text })
  }



  render() {
    var { classes } = this.props;

    var is_loading = this.state.loading_search //  Devices && Devices.length ? false : true;

    return(
      <form onSubmit={(event) => this.performSearch(event)} id="searchForm" className="form" >
        <Paper>
          <Typography className={classes.title} variant="h2">
            Welcome to LogicOne!
          </Typography>
          <Grid container spacing={5} alignItems="flex-end" className={classes.grid}>
            <Grid item md={10}>
              <FormControl variant="outlined">
                <InputLabel id="search" className={classes.label}>Search Term</InputLabel>
                <OutlinedInput
                  id="searchInput"
                  labelId="search"
                  labelWidth={78}
                  className={classes.textField}
                  placeholder="e.g.: TTB123456"
                  margin="normal"
                  value={this.state.search_term}
                  autoComplete="off"
                  onChange={(event) => this.updateSearchTerm(event.target.value)}
                />
                </FormControl>
            </Grid>
            <Grid item md={1}>
              <CircularIntegration text="Search" is_loading={is_loading} onClick={() => this.performSearch()}/>
            </Grid>
            { /* So these props are fake to hide these buttons on the summary screen for now.
                I'm hoping the form can be removed from the summary screen entirely at some point
                because it doesn't look right.
              */ }
            { this.props.reset_enabled &&
            <Grid item>
              <CircularIntegration text="Reset" is_loading={is_loading} onClick={() => this.resetSearch()}/>
            </Grid>
            }
            { this.props.auto_refresh &&
            <Grid item>
              <PageRefreshInput receivedAt={this.props.receivedAt} refreshFunction={() => this.performSearch()} seconds={60}/>
            </Grid>
            }
          </Grid>
        </Paper>
      </form>
    )
  }
}

function mapStateToProps(state) {
  const { loading } = state.devices
  const { search_term } = state.deviceSearch
  return { loading, search_term }
}

const mapDispatchToProps = {
  initDeviceSearch,
  resetDeviceSearch
}

const enhance = compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(ServiceSearchForm)
