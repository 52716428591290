import React from 'react'
import PropTypes from 'prop-types';
import { Link as RouterLink } from "react-router-dom";

import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandedIcon from '@material-ui/icons/ChevronRight';
import DescriptionIcon from '@material-ui/icons/Description';
// import DevicesIcon from '@material-ui/icons/Devices';
// import MailIcon from '@material-ui/icons/Mail';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import NotificationsIcon from '@material-ui/icons/Notifications';
import BuildIcon from '@material-ui/icons/Build';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { makeStyles } from '@material-ui/core/styles';

import menuItems from '../data/menuItems'
import { IS_EMBEDDED } from '../constants'

const useStyles = makeStyles( theme => ({
  drawer: props => ({
    width: props.width,
    flexShrink: 0
  }),
  drawerPaper: props => ({
    width: props.width,
  }),
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }
}));


export default function SideMenu(props) {
  const { drawer, drawerPaper, drawerHeader } = useStyles(props);
  return(
    <Drawer
      className={drawer}
      variant="persistent"
      anchor="left"
      open={props.open}
      classes={{
        paper: drawerPaper,
      }}
    >
      <div className={drawerHeader}>
        <IconButton onClick={props.closedrawer}>
          <ChevronLeftIcon />
           { /* theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon /> */ }
        </IconButton>
      </div>
      <Divider />
      <List>
      {
        menuItems.data.map( (item) => {
          return (
            <ListItemLink key={item.name} to={"/"} item={item} primary="Summary" icon={item.icon} />
          )
        })
      }
      {
        IS_EMBEDDED !== true &&
          <ListItem
            button
            onClick={props.logoutUser}
          >
            <ListItemIcon><ExitToAppIcon /></ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
      }


      </List>
      <Divider />
    </Drawer>
  )
}

function ListItemLink(props) {
  const { item, icon, padding } = props;
  const [open, setOpen] = React.useState(false);

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={item.url} {...itemProps} innerRef={ref} />
      )),
    [item.url],
  );

  if (item.children) {
    return (
      <React.Fragment>
        <ListItem onClick={() => setOpen(!open)} button>
          {icon ? <ListItemIcon><MenuItemIcon icon={icon}/></ListItemIcon> : null}
          <ListItemText primary={item.name} />
          { open ?
            <ExpandLess /> :
            <ExpandMore />
          }
        </ListItem>
        <Collapse
          in={ open }
          timeout="auto"
          unmountOnExit
        >
          {
          item.children.map( (item) => {
            return (
              <ListItemLink key={item.name} to={"/"} item={item} primary="Summary" padding={padding ? padding + 20 : 20} icon="Expanded" />
            )
          } )
          }
        </Collapse>
      </React.Fragment>
    )
  } else {
    return (
      <li>
        <ListItem button component={renderLink}>
          {icon ? <ListItemIcon><MenuItemIcon icon={icon}/></ListItemIcon> : null}
          { /* style={{"paddingLeft": padding + "px"}} */ }
          <ListItemText  primary={item.name} />
        </ListItem>
      </li>
    );
  }
}


ListItemLink.propTypes = {
  // item: PropTypes.element,
  icon: PropTypes.string,
  primary: PropTypes.string.isRequired,
  // to: PropTypes.string.isRequired,
};


function MenuItemIcon(props) {
  const { icon } = props;

  var Icon = <DashboardIcon />;
  switch (icon) {
    case 'Dashboard':
      return Icon
    case 'Alerts':
      return <NotificationsIcon />;
    case 'Maintenance':
      return <BuildIcon />;
    case 'Services':
      return <AccountTreeIcon />;
    case 'Reports':
      return <DescriptionIcon />;
    case 'Expanded':
      return <ExpandedIcon />;
    default:
      return null;
  }
}
