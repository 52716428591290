import { RSAA } from 'redux-api-middleware';
import { APIURL } from '../constants'

export const REPORTS_REQUEST = 'REPORTS_REQUEST'
export const REPORTS_RECEIVE = 'REPORTS_RECEIVE'
export const REPORTS_FAILURE = 'REPORTS_FAILURE'

export function initRequestReports() {
  var url = APIURL + '/api/v1/reports'
  return {
    [RSAA]: {
      endpoint: url,
      method: 'GET',
      types: [
        {
          type: REPORTS_REQUEST
        },
        REPORTS_RECEIVE, REPORTS_FAILURE
      ],
      headers: {

      }
    }
  }
}


export const REPORT_GROUPS_REQUEST = 'REPORT_GROUPS_REQUEST'
export const REPORT_GROUPS_RECEIVE = 'REPORT_GROUPS_RECEIVE'
export const REPORT_GROUPS_FAILURE = 'REPORT_GROUPS_FAILURE'

export function initRequestReportGroups() {
  var url = APIURL + '/api/v1/report_groups'
  return {
    [RSAA]: {
      endpoint: url,
      method: 'GET',
      types: [
        {
          type: REPORT_GROUPS_REQUEST
        },
        REPORT_GROUPS_RECEIVE, REPORT_GROUPS_FAILURE
      ],
      headers: {
      }
}
  }
}

export const REPORT_EXECUTION_REQUEST = 'REPORT_EXECUTION_REQUEST'
export const REPORT_EXECUTION_RECEIVE = 'REPORT_EXECUTION_RECEIVE'
export const REPORT_EXECUTION_FAILURE = 'REPORT_EXECUTION_FAILURE'

export function runReport(id) {
  var url = APIURL + '/api/v1/reports/report/'+id+'/run'
  return {
    [RSAA]: {
      endpoint: url,
      method: 'POST',
      types: [
        {
          type: REPORT_EXECUTION_REQUEST
        },
        REPORT_EXECUTION_RECEIVE, REPORT_EXECUTION_FAILURE
      ],
      headers: {
      }
}
  }
}

export function customReport(type,from,to) {
  var url = APIURL + '/api/v1/reports/report/custom?type='+type+'&from='+from+'&to='+to
  return {
    [RSAA]: {
      endpoint: url,
      method: 'POST',
      types: [
        {
          type: REPORT_EXECUTION_REQUEST
        },
        REPORT_EXECUTION_RECEIVE, REPORT_EXECUTION_FAILURE
      ],
      headers: {
      }
}
  }
}
