


export default function deviceGroups(state = {
  items: [], byId: null, currentDeviceGroup: null, hierarchy: { 1: [] }, visible_group_ids: []
}, action) {

  switch (action.type) {
    case 'REQUEST_DEVICE_GROUP':
      return Object.assign({}, state, {
        currentDeviceGroup: null,
      })
    case 'RECEIVE_DEVICE_GROUP':
      return Object.assign({}, state, {
        currentDeviceGroup: action.payload.device_group,
        visible_group_ids: action.payload.ids_to_display,
        hierarchy: { ...state.hierarchy, ...action.payload.sorted_by_parent },
        byId: !state.byId ? action.payload.ById : { ...state.byId, ...action.payload.ById }
      })
    case 'RECEIVE_DEVICE_GROUPS':
      return Object.assign({}, state, {
        items: action.payload,
        receivedAt: Math.floor(new Date() / 1000)
      })
    default:
      return state
  }
}
