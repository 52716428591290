export default function deviceAlerts(state = {
  alerts: []
}, action) {
  switch (action.type) {
    case 'ALERTS_SUCCESS':
      return Object.assign({}, state, {
        byDeviceId: mapInstanceIdsToDeviceIds(action.payload)
      })
    default:
        return state
    }
}

// function alertsByDeviceId () {
//   convertArrayToObject(action.payload, 'monitorObjectId')
//
//   const convertArrayToObject = (array, key) =>
//      array.reduce((obj, item) => ((obj[[item[key]]] = item), obj), {});
//
// }


function mapInstanceIdsToDeviceIds(array) {
  var mappings = {};
  array.forEach((item) => {
    mappings[item.monitorObjectId] = mappings[item.monitorObjectId] || []
    mappings[item.monitorObjectId].push(item.id)
  })
  return mappings
}
