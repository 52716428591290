export default function maintenance(state = {
  sdts: [],
  byType: {
    DeviceSDT: [],
    DeviceGroupSDT: [],
    DeviceDataSourceSDT: [],
    CollectorSDT: []
  }
}, action) {
  switch (action.type) {
    case 'SDT_SUCCESS':
      return Object.assign({}, state, {
        sdts: action.payload,
        byType: sortTypes(action.payload)
      })
    case 'ADD_SDT_SUCCESS':
      return Object.assign({}, state, {
        
      })
    case 'UPDATE_SDT_SUCCESS':
      return Object.assign({}, state, {
        
      })
    case 'DELETE_SDT_SUCCESS':
      console.log(action.payload)
      return Object.assign({}, state, {
          
      })
    default:
        return state
    }
}

function sortTypes(allSDTs) {
  
  var x, y;
  var object = {};

  for(x=0; x<allSDTs.length; x++) {

    if(typeof(object[allSDTs[x].type]) === 'undefined')
      object[allSDTs[x].type] = [allSDTs[x]]
    else
      object[allSDTs[x].type].push(allSDTs[x])

  }

  y = Object.assign({}, object);

  return y
}


/*
    switch (allSDTs[x].type) {
      case 'DeviceGroupSDT':
        object
        return
      case 'DeviceSDT':

        return
      case 'DeviceDataSourceInstanceSDT':

        return
      case 'DeviceDataSourceInstanceGroupSDT':

        return
      case 'DeviceDataSourceSDT':

        return
      case 'CollectorSDT':

        return
      case 'ServiceSDT':

        return
      case 'DeviceBatchJobSDT':

        return
      case 'DeviceClusterAlertDefSDT':

        return
      case 'DeviceEventSourceSDT':

        return
      case 'WebsiteCheckpointSDT':

        return
      case 'WebsiteGroupSDT':

        return
      case 'WebsiteSDT':
    }
    */