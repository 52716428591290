import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import AsyncDropDown from '../components/AsyncDropDown';
import ReportsTable from '../containers/ReportsTable';
import { runReport, customReport } from '../actions/reports'
import { initDeviceSearch } from '../actions/devices';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ExportToCsv } from 'export-to-csv';
import { epochToTimestampWithYears } from '../libs/utils'
import { compose } from 'redux';

const styles = theme => ({
    root: {
      width: '100%',
      height: '',
      cursor: 'pointer',
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      paddingRight: '50px',
      alignItems: 'center',
      top: '30px',
    },
    dateSelection: {
      position: "relative"
    },
    dateGap: {
      marginBottom: 10
    },
    fab: {
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    }
})

const StyledTooltip = withStyles((theme) => ({
  tooltipPlacementTop: {
    margin: "4px 0",
  },
  tooltip: {
    boxShadow: theme.shadows[1],
    fontSize: 11
  }
}))(Tooltip);

const columnsAvail = [
  {
      Header: 'Device',
      accessor: 'device'
  },
  {
      Header: 'PingLossPercent',
      accessor: 'average'
  }
]

 const columnsIO = [
  {
      Header: 'Device',
      accessor: 'device'
  },
  {
      Header: 'InOctets: Max',
      accessor: 'max'
  },
  {
      Header: 'InOctets: Average',
      accessor: 'average'
  },
  {
      Header: 'OutOctets: Max',
      accessor: 'max'
  },
  {
      Header: 'OutOctets: Average',
      accessor: 'average'
  }
]

const prevDate = (days) => {

  var now = new Date().getTime()
  var then = now - (days * 86400000)

  return new Date(then).toISOString().substring(0,16)
}

class RunReportContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateSliderType: "day",
      dateSliderValue: 0,
      filter: [],
      reportRunning: false,
      reportRun: false,
      report: [],
      toDownload: [],
      tooltipLabel: 'Last 24 hours',
      now: prevDate(0),
      then: prevDate(1),
      customNow: 0,
      customThen: 0,
      dateSelection: false,
      runButtonHidden: false,
      dlButtonHidden: true
    }
    this.marks = [
      {
        value: 0,
        label: 'Day',
        type: 'day'
      },
      {
        value: 25,
        label: 'Week',
        type: 'week'
      },
      {
        value: 50,
        label: 'Month',
        type: 'month'
      },
      {
        value: 75,
        label: 'Year',
        type: 'year'
      },
      {
        value: 100,
        label: 'Custom',
        type: 'custom'
      }
    ];
    this.titles = {
      "availability": "Availability",
      "circuitMetrics": "Circuit Metrics"
    };
    this.val = 0;
  }

  setTitle(title) {
    this.setState({ title: title });
  }

  componentDidMount() {
    this.props.setParentTitle('Run Report: ' + this.titles[this.props.match.params.id])
    this.props.dispatch(initDeviceSearch())

  }

  componentDidUpdate(prevProps) {
    if(this.props.results !== prevProps.results) {
      this.setState({ reportRunning: false, runButtonHidden: false, reportRun: true, report: this.props.results[this.props.match.params.id][this.state.dateSliderType].results })
      if(this.props.results[this.props.match.params.id][this.state.dateSliderType].results.length > 0) {
        this.setState({ dlButtonHidden: false })
      } else {
        this.setState({ dlButtonHidden: true })
      }
    }
    if(this.props.match.url !== prevProps.match.url) {
      this.props.setParentTitle('Run Report: ' + this.titles[this.props.match.params.id])
    }

  }

  valueLabelFormat = (value) => {
    return this.marks.findIndex((mark) => mark.value === value) + 1;
  }

  executeReport = (time) => {
    const { items, device_group_path, results } = this.props
    var reportTime, reportType, accountName, reportName, reportId;

    if(this.props.match.params.id === "circuitMetrics")
      reportType = "IO";
    else
      reportType = "Avail";

    if(time === 0)
      reportTime = "DAY";
    else if(time === 25)
      reportTime = "WEEK";
    else if(time === 50)
      reportTime = "MONTH"
    else if(time === 75)
      reportTime = "YEAR";
    else {
      this.props.dispatch(customReport(reportType,this.state.then.replace("T"," "),this.state.now.replace("T"," ")))
      this.setState({ reportRunning: true, runButtonHidden: true })
      return
    }

    accountName = device_group_path.split("/")[1];
    reportName = accountName + " " + reportTime + " " + reportType;

    for(var i=0; i<items.length; i++) {
      if(items[i].name === reportName)
        reportId = items[i].id;
    }

    var timestamp = results[this.props.match.params.id][this.state.dateSliderType].timestamp
    const currentTime = (new Date()).getTime()

    // Only run if report has not been ran in past 30 mins
    if (currentTime - timestamp > 180000) {
      this.setState({ reportRunning: true })
      this.props.dispatch(runReport(reportId))
    } else {
      console.log('no need to run report')
    }
  };

  getFilteredDevices = (devices) => {
    this.setState({ filter: devices })
  }

  updateSliderRange = (e, val) => {
    if (val === 0) {
      this.setState({ dateSliderType: 'day', tooltipLabel: 'Last 24 hours', dateSelection: false, then: prevDate(1), now: prevDate(0) })
    } else if (val === 25) {
      this.setState({ dateSliderType: 'week', tooltipLabel: 'Last 7 days', dateSelection: false, then: prevDate(7), now: prevDate(0) })
    } else if (val === 50) {
      this.setState({ dateSliderType: 'month', tooltipLabel: 'Last 30 days', dateSelection: false, then: prevDate(30), now: prevDate(0) })
    } else if (val === 75) {
      this.setState({ dateSliderType: 'year', tooltipLabel: 'Last 365 days', dateSelection: false, then: prevDate(365), now: prevDate(0) })
    } else {

      if(this.state.customNow === 0 || this.state.customThen === 0) {
        this.setState({ dateSliderType: 'custom', tooltipLabel: 'Custom time range', dateSelection: true, then: prevDate(1), now: prevDate(0)})
      } else {
        this.setState({ dateSliderType: 'custom', tooltipLabel: 'Custom time range', dateSelection: true, then: this.state.customThen, now: this.state.customNow})
      }

    }
    this.setState({ dateSliderValue: val })

    if(this.props.results[this.props.match.params.id][this.state.dateSliderType].results.length > 0) {
      this.setState({ dlButtonHidden: false })
    } else {
      this.setState({ dlButtonHidden: true })
    }
  }

  changeStartDate = (e) => {
    var end = new Date(this.state.now.replace("T"," ")).getTime()
    var start = new Date(e.target.value.replace("T"," ")).getTime()

    if(end > start) {
      this.setState({ customThen: e.target.value, then: e.target.value, runButtonHidden: false })
    }
    else if(isNaN(start) || isNaN(end)) {
      this.setState({ customThen: e.target.value, then: e.target.value })
    }
    else {
      // Notify
    }
  }

  changeEndDate = (e) => {
    var start = new Date(this.state.then.replace("T"," ")).getTime()
    var end = new Date(e.target.value.replace("T"," ")).getTime()

    if (end > start ) {
      this.setState({ customNow: e.target.value, now: e.target.value, runButtonHidden: false })
    }
    else if(isNaN(start) || isNaN(end)) {
      this.setState({ customNow: e.target.value, now: e.target.value })
    }
    else {
      // Notify
    }
  }

  updateDownloadList = (result) => {
    this.setState({ report: result })
  }

  download = (event) => {

    const { results } = this.props
    const { id } = this.props.match.params
    var columns
    var file = this.titles[this.props.match.params.id] + " Report " + epochToTimestampWithYears(results[id][this.state.dateSliderType].timestamp).split('-')[0]
    if(this.props.match.params.id === 'circuitMetrics')
      columns = columnsIO
    else
      columns = columnsAvail

    var tableRows = this.props.results[this.props.match.params.id][this.state.dateSliderType].results
    console.log(tableRows)
    var dataToDownload = []
    for (var index = 0; index < tableRows.length; index++) {
       let record_to_download = {}
       for(var colIndex = 0; colIndex < columns.length; colIndex ++) {
          if(colIndex === 0) {
            record_to_download[columns[colIndex].Header] = tableRows[index][columns[colIndex].accessor]
          } else {
            record_to_download[columns[colIndex].Header] = tableRows[index][(columns[colIndex].Header).split(':')[0]][columns[colIndex].accessor]
          }
       }
       dataToDownload.push(record_to_download)
    }

    this.setState({ toDownload: dataToDownload }, () => {
      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: this.titles[this.props.match.params.id] + " Report, Time Range: " + this.state.then.replace("T"," ") + "-" + this.state.now.replace("T"," "),
        filename: file.slice(0, -1),
        useBom: true,
        useKeysAsHeaders: true
      };

      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(this.state.toDownload);
    })
  }

  render() {
    const { Devices, classes } = this.props
      return(
        <React.Fragment>
          <div className={classes.root}>
          <Fab
            variant="extended"
            color="primary"
            onClick={this.download}
            className={classes.fab}
            disabled={this.state.dlButtonHidden}
          >
            Download Report
          </Fab>
          </div>
          <Grid container spacing={4} alignItems="flex-end">
            <Grid item>
              <Typography id="discrete-slider-restrict">
                  Time Range
              </Typography>
              <StyledTooltip title={this.state.tooltipLabel} placement="top">
                <Slider
                  style={{width: 220}}
                  id="time-range"
                  defaultValue={0}
                  valueLabelFormat={this.valueLabelFormat}
                  aria-labelledby="discrete-slider-restrict"
                  step={null}
                  valueLabelDisplay="off"
                  marks={this.marks}
                  onChange={ (e, val) => this.updateSliderRange(e, val) }
                />
              </StyledTooltip>
            </Grid>
            <Grid item>
            <div styles={classes.dateSelection}>
                <form className={classes.dateGap} noValidate>
                  <TextField
                    disabled={!this.state.dateSelection}
                    id="datetime-start"
                    type="datetime-local"
                    className={classes.textField}
                    value={this.state.then}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      max: this.state.now || this.prevDate(0)
                    }}
                    label="Start Date"
                    onChange={this.changeStartDate}
                  />
                </form>
                <form noValidate>
                  <TextField
                    disabled={!this.state.dateSelection}
                    id="datetime-end"
                    type="datetime-local"
                    className={classes.textField}
                    value={this.state.now}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      min: this.state.then
                    }}
                    label="End Date"
                    onChange={this.changeEndDate}
                  />
                </form>
          </div>
            </Grid>
            <Grid item>
              <Grid container spacing={2} alignItems="flex-end" >
                <Grid item>
                  <Button
                      variant="contained"
                      color="primary"
                      onClick={() => this.executeReport(this.state.dateSliderValue)}
                      disabled={this.state.runButtonHidden}
                    >
                      Run Report
                  </Button>
                </Grid>
                {
                this.state.reportRunning ? (
                  <Grid item>
                    <CircularProgress size={24} hidden={true} />
                  </Grid>
                ) : null
                }
              </Grid>
            </Grid>
            <Grid item>
              <AsyncDropDown callbackFromParent={(Devices) => this.getFilteredDevices(Devices)} devices={Devices} type={this.props.match.params.id}/>
            </Grid>

          </Grid>
          <ReportsTable filter={this.state.filter} callback={(result) => this.updateDownloadList(result)} type={this.props.match.params.id} time={this.state.dateSliderType}></ReportsTable>
        </React.Fragment>
      )
  }
}
function mapStateToProps(state) {
  const { Devices } = state.deviceSearch
  const { items, results } = state.reports
  const { device_group_path } = state.userSession.properties
  return { Devices, items, results, device_group_path }
}

const enhance = compose(
  withStyles(styles),
  connect(mapStateToProps)
)
export default enhance(RunReportContainer)
