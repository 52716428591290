import { RSAA } from 'redux-api-middleware';
import { APIURL } from '../constants'
import { encode } from "base-64";


export const SESSION_REQUEST = 'SESSION_REQUEST'
export const SESSION_RECEIVE = 'SESSION_RECEIVE'
export const SESSION_FAILURE = 'SESSION_FAILURE'

export function initRequestSession() {
  var url = APIURL + '/api/v1/session'
  return {
    [RSAA]: {
      endpoint: url,
      method: 'GET',
      types: [
        {
          type: SESSION_REQUEST
        },
        SESSION_RECEIVE, SESSION_FAILURE
      ],
      headers: {
      },
    }
  }
}

export const JWT_REQUEST = 'JWT_REQUEST'
export const JWT_RECEIVE = 'JWT_RECEIVE'
export const JWT_FAILURE = 'JWT_FAILURE'

export function fetchJWT(username, password, account_id) {
  if (!account_id) {
    return;
  }

  var string = encode(`${username}:${password}`);

  var url = APIURL + '/api/v1/token-jwt?account_id=' + account_id
  return {
    [RSAA]: {
      endpoint: url,
      method: 'GET',
      types: [
        {
          type: JWT_REQUEST
          // meta: { 'goods': goods }
        },
        JWT_RECEIVE, JWT_FAILURE
      ],
      headers: {
         "Authorization": 'Basic ' + string
      }
    }
  }
}

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_RECEIVE = 'LOGOUT_RECEIVE'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'

// this just logs out the user locally for now.
// It could be extended to perform logout on the server too.
export function logoutUser() {
  return {
    type: LOGOUT_RECEIVE
  }
}
