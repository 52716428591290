import React, { Component } from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import LoginContainer from  './LoginContainer'
import LoadingContainer from  './LoadingContainer'

import { initRequestSession, fetchJWT } from '../actions/session';

const drawerWidth = 240;

const useStyles = (theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    content: {
      flexGrow: 1,
      overflow: 'auto',
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }
  });



class AuthContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      loading: true,
      is_offline: false,
      logged_in: false,
      open: false,
      wan_group_dash_id: null
    }
  }

  componentDidMount() {
    this.checkExistingSession()
    // this.updateLoggedStatus();
    if (localStorage.getItem('jwt') || this.props.logged_in === true) {
      this.setState({ logged_in: true, loading: false })
    } else {
      this.setState({ logged_in: false, loading: false })
    }
  }

  componentWillUnmount() {

  }

  async checkExistingSession() {
    const jwt_token = localStorage.getItem('jwt');
    if (!jwt_token) {

    }
    if (jwt_token) {
      const session = await this.props.initRequestSession()
      if (session.type && session.type !== 'SESSION_FAILURE') {
        this.setState({ is_offline: false, logged_in: true })
      } else {
        console.log('problem loading session from token');
        localStorage.removeItem('jwt');
      }
    }

    this.setState({ loading: false })
  }

  handleDrawerOpen() {
    this.setState({open: true});
  }

  handleDrawerClose() {
    this.setState({open: false});
  }

  setTitle(title) {
    this.setState({ title: title });
  }

  render() {
    if (this.state.loading === true) {
      return (
        <Grid container justify="center" style={{ paddingTop: '200px' }}>
          <CircularProgress size={24}  />
          <Typography>Loading initial Resource Monitor data2..</Typography>
        </Grid>
      )
    } else if (this.props.logged_in === true) {
      return (
        <LoadingContainer/>
      )
    } else {
      return (
        <LoginContainer setParentTitle={(title) => this.setTitle(title)}/>
      )
    }
  }
}

const mapDispatchToProps = {
  initRequestSession,
  fetchJWT
}

const mapStateToProps = (state) => {
  var { logged_in } = state.userSession
  // devices: state.devices.byId,
  return {
    logged_in
  }
};


const enhance = compose(
    withStyles(useStyles),
    connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(AuthContainer)
