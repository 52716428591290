import { createStore, applyMiddleware } from 'redux';
import { apiMiddleware } from 'redux-api-middleware';
import reducers from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import jwtMiddleware from './middleware/jwtMiddleware'

// const reducer = combineReducers(reducers);
const createStoreWithMiddleware = composeWithDevTools(
  applyMiddleware(
    jwtMiddleware,
    apiMiddleware
  )
)(createStore);

export default function configureStore(initialState) {
  return createStoreWithMiddleware(reducers, initialState);
}
