import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { withStyles, createStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { fromUnixTime, format } from 'date-fns'
import { animateScroll as scroll } from 'react-scroll'

import { initDeviceSearch, resetDeviceSearch } from '../actions/devices';
import { initRequestWidgetData } from '../actions/dashboard'
import { initRequestAlerts } from '../actions/alerts'

import DeviceSearchTable from './DeviceSearchTable'
import CircularIntegration from '../components/CircularIntegration'
import PageRefreshInput from '../components/PageRefreshInput'
import BackButton from '../components/BackButton';
// import { data } from '../data/graphs'

const styles = (theme) =>
  createStyles({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 400,
    },
    sticky: {
      position: '-webkit-sticky', /* Safari */
      // eslint-disable-next-line
      position: 'sticky',
      top: 0
    }
  });

class DevicesContainer extends Component {
  constructor(props) {
    super(props);
    this.timer = null
    this.state = {
      search_term: props.search_term || "",
      loading_search: false,
      loadingWidgets: false,
    }
  }


  componentDidMount() {
    scroll.scrollToTop();

    this.props.setParentTitle('Connectivity')

    // this.props.initDeviceSearch(this.state.search_term)
    // Cheeky way to just get all devices on load but we don't want
    // to refresh the device table if we have devices cached already.
    // const params = new URLSearchParams(this.props.location.search);
    // var customer_name = params.get('search_term') || ""
    // this.setState({ search_term: customer_name })
    if (!this.props.Devices) {
      this.performSearch()
    }
  }

  async performSearch(event) {
    // console.log(event)
    event && event.preventDefault()
    this.setState({ loading_search: true })
    await this.props.initDeviceSearch(this.state.search_term)
    await this.props.initRequestAlerts()
    this.setState({ loading_search: false })
  }

  resetSearch() {
    this.setState({ search_term: "", loading_search: true })
    this.props.resetDeviceSearch()
    this.setState({ loading_search: false })
  }

  updateSearchTerm(input_text) {
    this.setState({ search_term: input_text })
  }

  async handleRowClick(row) {
    this.props.history.push('/device/' + row.id, { Device: row })
    // console.log('clicked on the row ' + wan_dash_id)
    // console.log(this.state)
    // await this.props.initRequestDashboard(wan_dash_id)
    // this.setState({ loadingWidgets: false })
  }

  epochToTimestamp(epoch) {
    var date = fromUnixTime(epoch);
    return format(date, 'HH:mm:ss dd/MM/yy')
  }

  render() {
    const { classes, Devices, searchResults, api_failure, dwsAlertDevicesIds } = this.props;

    var is_loading = this.state.loading_search //  Devices && Devices.length ? false : true;

    var table = <div>No devices loaded - check connection to API.</div>

    if (!api_failure) {
      table = <DeviceSearchTable devicesInError={dwsAlertDevicesIds} items={searchResults} clickRow={(row) => { this.setState({ loadingWidgets: 'true' }); this.handleRowClick(row) }}/>
    }
    return(
      <React.Fragment>
      <BackButton link = '/summary/'/>
      <div className="container">
        <div className="sticky" >
          <form onSubmit={(event) => this.performSearch(event)} id="searchForm" className="form" >
            <Grid container spacing={2} alignItems="flex-end" >
              <Grid item>
                <TextField
                  id="searchInput"
                  label="Search term"
                  className={classes.textField}
                  placeholder="e.g.: TTB123456"
                  margin="normal"
                  value={this.state.search_term}
                  autoComplete="off"
                  onChange={(event) => this.updateSearchTerm(event.target.value)}
                />
              </Grid>
              <Grid item>
                <CircularIntegration text="Search" is_loading={is_loading} onClick={() => this.performSearch()}/>
              </Grid>
              <Grid item>
                <CircularIntegration text="Reset" is_loading={is_loading} onClick={() => this.resetSearch()}/>
              </Grid>
              <Grid item>
                <PageRefreshInput receivedAt={this.props.receivedAt} refreshFunction={() => this.performSearch()} seconds={60}/>
              </Grid>
            </Grid>
          </form>
          <Grid container spacing={2} alignItems="flex-end">
          { Devices ?
            table
          :
            <CircularProgress className={classes.progress} />
          }
          </Grid>
          { /* Devices &&
          <p>Last updated: {this.epochToTimestamp(this.props.receivedAt)}</p>
          */ }
        </div>
      </div>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  const { deviceIds, Devices, api_failure, search_term, receivedAt } = state.deviceSearch
  const { dwsAlertDevicesIds } = state.alerts
  const { byId } = state.devices

  var searchResults = []
  if (deviceIds && deviceIds.length > 0) {
    deviceIds.forEach(id => {
      searchResults.push(byId[id]);
    })
  } else {
    if (byId) {
      Object.keys(byId).forEach(id => {
        searchResults.push(byId[id]);
      })
    }
  }

  // @todo change the table to render from object keyed by ids and then change that push
  // above to make an object of keyed elements.
  return { Devices, searchResults, api_failure, search_term, dwsAlertDevicesIds, receivedAt }
}

const mapDispatchToProps = {
  initDeviceSearch,
  resetDeviceSearch,
  initRequestWidgetData,
  initRequestAlerts
}



const enhance = compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(DevicesContainer)
