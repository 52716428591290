import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fromUnixTime, format } from 'date-fns'

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import { initRequestDashboard, getDashboardFromReportId } from '../actions/dashboard';
import { initRequestDeviceGroup, initRequestAllDeviceGroups } from '../actions/devicegroup'

import AlertOverviewWidget from '../components/widgets/AlertOverviewWidget'
import WanViewWidget from '../components/widgets/WanViewWidget'
import PageRefreshInput from '../components/PageRefreshInput'

class DashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
    }
  }

  componentDidMount() {
    if (!this.props.properties) {
      this.props.setParentTitle('Dashboard View')

    } else {
      const { id } = this.props.match.params
      console.log('id is ' + id)
      console.log(this.props.properties);

      this.props.setParentTitle('Dashboard')

      // Switching back to wan view dash...
      this.props.initRequestDashboard(this.props.wan_group_dash_id)
    }
  }

  componentWillUnmount() {
  }

  async getAllDeviceGroups() {
    this.props.dispatch(initRequestAllDeviceGroups())
  }

  async loadDashboard(id) {
    const deviceGroup = await this.props.dispatch(initRequestDeviceGroup(id))
    var reportId = deviceGroup.payload.device_group.group_report_id

    const Dashboard = await this.props.dispatch(getDashboardFromReportId(reportId))
    var dashboardId = Dashboard.payload.id
    this.props.dispatch(initRequestDashboard(dashboardId))
  }

  loadWidgets(id) {
    console.log('loading widgetssss for id ' + id)
    // this.loadDashboard(id)
  }


  epochToTimestamp(epoch) {
    var date = fromUnixTime(epoch);
    return format(date, 'HH:mm:ss dd/MM/yy')
  }

  render() {
    var { items, widgets, properties, receivedAt, instanceToDeviceIds } = this.props
    var body = <CircularProgress size={24}  />
    var DisplayedWidgets
    if (Object.entries(instanceToDeviceIds).length === 0) {
      DisplayedWidgets = <AlertOverviewWidget deviceGroups={items} handleClick={(id) => this.loadWidgets(id) }/>
    } else {
      DisplayedWidgets = <WanViewWidget idMappings={instanceToDeviceIds} widgets={widgets} handleClick={(id) => this.loadWidgets(id) }/>
    }

    if (properties) {
      body =
      <React.Fragment>
        <Grid container spacing={2} justify="flex-end">
          <Grid item xs={5} sm={5} md={5} lg={5}>
            <PageRefreshInput receivedAt={receivedAt} refreshFunction={() => this.props.initRequestDashboard(this.props.wan_group_dash_id) }/>
          { /*
            <Typography style={{ marginBottom: 20 }} variant="caption">
              { receivedAt && 'Last updated: ' + this.epochToTimestamp(this.props.receivedAt)}
            </Typography>
            */
          }
          </Grid>
        </Grid>
        {DisplayedWidgets}
      </React.Fragment>
    }

    return(
      <Container maxWidth={false}>
        {body}
      </Container>
    )
  }
}

function mapStateToProps(state) {
  const { properties } = state.userSession
  const { widgets } = state.dashboard
  const { instanceToDeviceIds } = state.deviceSearch
  const { items, receivedAt } = state.deviceGroups

  return { widgets, properties, items, receivedAt, instanceToDeviceIds }
}

const mapDispatchToProps = {
  initRequestDashboard,
  initRequestAllDeviceGroups,
  getDashboardFromReportId
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer)
