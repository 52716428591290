import { fromUnixTime, getUnixTime, format } from 'date-fns'


const convertArrayToObject = (array, key) =>
   // eslint-disable-next-line
   array.reduce((obj, item) => ((obj[[item[key]]] = item), obj), {});

export default convertArrayToObject

export const formattedUptime = (seconds) => {
  var numdays = Math.floor(seconds / 86400);
  var numhours = Math.floor((seconds % 86400) / 3600);
  var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
  // var numseconds = ((seconds % 86400) % 3600) % 60;
  return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}

export const daysUptime = (seconds) => {
  var numdays = Math.floor(seconds / 86400);
  return numdays + " days ";
}

export const epochToTimestamp = (epoch) => {
  epoch = epoch.toString().substring(0,10)
  var date = fromUnixTime(epoch);
  return format(date, 'MMM do HH:mm')
}

export const epochToTimestampWithYears = (epoch) => {
  epoch = epoch.toString().substring(0,10)
  var date = fromUnixTime(epoch);
  return format(date, 'MMM do yyyy - HH:mm')
}

export const timestampToEpoch = (timestamp) => {
  return getUnixTime(timestamp);
}
