import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelector } from "react-redux";
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';

function desc(a, b, orderBy) {
  if (a[orderBy] === undefined) a[orderBy] = ''
  if (b[orderBy] === undefined) b[orderBy] = ''
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headCellsIO = [
  { id: 'deviceName', numeric: false, disablePadding: false, label: 'Device Name' },
  { id: 'InOctetsMax', numeric: false, disablePadding: false, label: 'InOctets: Max' },
  { id: 'InOctetsAvg', numeric: false, disablePadding: false, label: 'InOctets: Avg' },
  { id: 'OutOctetsMax', numeric: false, disablePadding: false, label: 'OutOctets: Max' },
  { id: 'OutOctetsAvg', numeric: false, disablePadding: false, label: 'InOctets: Avg' }
];

const headCellsAvail = [
  { id: 'deviceName', numeric: false, disablePadding: false, label: 'Device Name' },
  { id: 'PingLossPercent', numeric: false, disablePadding: false, label: 'PingLossPercent: Avg' }
]

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort, type } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  var headCells;
  if(type === 'circuitMetrics')
    headCells = headCellsIO
  else
    headCells = headCellsAvail

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    minHeight: 0
},
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();

  return (
    <Toolbar
      className={clsx(classes.root)}
    >
      <div className={classes.title}>

      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>

      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {

};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  row: {
    cursor: 'pointer'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function ReportsTable(props) {
  const { type, time, filter } = props
  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('start_date');
  const [selected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  var rows = useSelector(state => state.reports.results[type][time].results || [])
  var dps;

  var filterDevices = []

  if (type === 'circuitMetrics')
    dps = ['InOctets', 'OutOctets']
  else
    dps = ['PingLossPercent']

  if (filter.length > 0) {
    filter.forEach(element => {
      var found = rows.find(elem => elem.device.includes(element))
      if (found)
        filterDevices.push(found)
    })
    rows = filterDevices
    //callback(rows)
  }


  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size='small'
        >
        <EnhancedTableHead
          classes={classes}
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          rowCount={rows.length}
          type={type}
        />
        <TableBody>
          {
            stableSort(rows, getSorting(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
                return (

                  <TableRow
                    hover
                    onClick={event => console.log(event, row)}
                    role="checkbox"
                    tabIndex={-1}
                    key={row.device}
                    className={classes.row}
                  >
                    <TableCell >
                      {row.device}
                    </TableCell>
                    {

                      type === 'circuitMetrics' ? (
                        <React.Fragment>
                          <TableCell>
                            {row[dps[0]] && row[dps[0]].max}
                          </TableCell>
                          <TableCell>
                            {row[dps[0]] && row[dps[0]].average}
                          </TableCell>
                          <TableCell>
                              {row[dps[1]] && row[dps[1]].max}
                          </TableCell>
                          <TableCell>
                            {row[dps[1]] && row[dps[1]].average}
                          </TableCell>
                        </React.Fragment>
                      ) : (
                      <TableCell>
                        {row[dps[0]] && row[dps[0]].average}
                      </TableCell>
                      )
                    }
                  </TableRow>
                );
            })
          }
          {emptyRows > 0 && (
            <TableRow style={{ height: 49 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100, 1000]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      </Paper>
    </div>
  );
}
