import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 400,
    minWidth: 400,
  },
  summary: {
    padding: theme.spacing(1, 1),
    marginBottom: 10,
    width: '400 px'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  }
}));


export default function SubmitTicket({ device, state, callbackFunction }) {
  const [open, setOpen] = React.useState(state);
  const classes = useStyles();

  useEffect(() => {
    if (state) {
      setOpen(state);
    }
  }, [state])


  const handleClose = () => {
    setOpen(false)
    callbackFunction(true)
  };

  const variables = [
        {
            name: "Device:",
            data: device.label
        },
        {
            name: "Customer:",
            data: device['Customer Name']
        },
        {
            name: "Postcode:",
            data: device['postcode']
        },
        {
            name: "Circuit Reference:",
            data: device['circuit reference']
        },
        {
            name:"Provider:",
            data: device['circuit reference provider']
        }
        ]



  const outputClipboard = (e) => {
        var outputVariables = ""
        for(var i=0; i<variables.length; i++) {
            var value = variables[i].data 
            if (value === undefined) {
                value = "-"
            }
            outputVariables += variables[i].name + " " + value + '\n'
        }
        return outputVariables
    }

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Submit A Ticket</DialogTitle>
        <DialogContent style={{overflowY: 'hidden'}}>
            <Paper className={classes.summary}>
                <Grid container spacing={1}>
                {
                    variables.map((info) => {
                        return(
                         <React.Fragment>
                            <Grid item xs={4} sm={2}>
                            <Typography >
                                {info.name}
                            </Typography>
                            </Grid>
                            <Grid item xs={8} sm={4}>
                            <Typography >
                                {info.data || '-'}
                            </Typography>
                            </Grid>
                         </React.Fragment>
                        )
                    })
                }
                </Grid>
            </Paper>

        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {navigator.clipboard.writeText(outputClipboard(variables))}}
            color="primary"
            variant="contained"
        >
            Copy
            </Button>
            <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
