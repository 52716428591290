import React, { Component } from 'react'
import { connect } from 'react-redux'

import ReportWidget from '../components/widgets/ReportOverviewWidget'
import { initRequestReports } from '../actions/reports'

class ReportsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  setTitle(title) {
    this.setState({ title: title });
  }


  componentDidMount() {
    
    if (typeof(this.props.reports) == 'undefined') {
      this.props.dispatch(initRequestReports());
    } else {
      // we already have the reports.
    }
    this.props.setParentTitle('Reports Selection')
  }


  render() {
    const { reports } = this.props
      return(
          <React.Fragment>
            <ReportWidget devices={reports}></ReportWidget>
          </React.Fragment>
      )
  }
}

function mapStateToProps(state, ownProps) {
  const { reports } = state.reports
  return { reports }
}

export default connect(mapStateToProps)(ReportsContainer)
