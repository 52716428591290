import React, { Component } from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid';

import SummaryStat from '../components/SummaryStat'
import ServiceSearchForm from  './ServiceSearchForm'

// import { initRequestSDTs } from '../actions/maintenance'

class SummaryStats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
    }
  }


  componentDidMount() {
    this.props.setParentTitle('Summary')
    // this.props.dispatch(initRequestSDTs())
  }

  render() {
    var { devices, byStatus } = this.props;
    if (!byStatus) {
      return null;
    }

    var live_services = devices ? Object.keys(devices).length : 'N/A'
    var tiles = [
      {
        title: 'Live Services',
        count: live_services,
        link: '/services/connectivity'
      },
      {
        title: 'Critical Services',
        count: byStatus['Critical'] ? byStatus['Critical'].length : 0,
        link: '/alerts?filter=Critical',
        status: 'Critical'
      },
      {
        title: 'Services in Error',
        count: byStatus['Error'] ? byStatus['Error'].length : 0,
        link: '/alerts?filter=Error',
        status: 'Error'
      },
      {
        title: 'Services in Warning',
        count: byStatus['Warning'] ? byStatus['Warning'].length : 0,
        link: '/alerts?filter=Warning',
        status: 'Warning'
      }
    /*
    {
      title: 'Orders',
      count: 23,
      // link: '#'
    },
    {
      title: 'Open Trouble Tickets',
      count: 15,
      // link: '#'
    }
    */
    ]
    return(
      <div>
        <ServiceSearchForm history={this.props.history}/>
        <Grid container spacing={5} style={{'paddingTop': '20px'}}>
          {
            tiles.map((tile, index) => {
              return(
                <SummaryStat key={index} tile={tile}/>
              )
            })
          }
        </Grid>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { byId, loading } = state.devices
  const { byStatus } = state.alerts
  return { devices: byId, loading, byStatus }
}

export default connect(mapStateToProps)(SummaryStats)
