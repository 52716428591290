import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import FileCopyIcon from '@material-ui/icons/FileCopy';


const styles = theme => ({
  root: {
    // padding: theme.spacing(3, 2),
    height: 90,
    overflow: 'hidden',
    padding: theme.spacing(2),
    color: 'red',
    cursor: 'pointer'
  },
  paper: {
    padding: theme.spacing(2)
  },
  Critical: {
      backgroundColor: 'red'
  },
  confirmedWarnAlert: {
    backgroundColor: 'blue'
  },
  Warning: {
    backgroundColor: 'orange'
  },
  confirmedErrorAlert: {
    backgroundColor: 'yellow'
  },
  confirmedCriticalAlert: {
    backgroundColor: 'blue'
  },
  noAlert: {
    backgroundColor: 'green'
  },
  iconbar: {
    width: 'fit-content'
  }
});


class ReportOverviewWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      group_selected: false
    }
  }

  dismiss() {
    this.props.unmountMe();
}

  render() {
    var reports = [{name: 'Availability', link: 'availability'}, {name: 'Circuit Metrics', link: 'circuitMetrics'}]
    if (reports) {
      return(
          <div >
            <Grid container spacing={1}  >
              {
                reports.map((report, index) => {
                  return (
                    <Grid key={report.name} item xs={3} lg={2} xl={2} className={'iconbar'}>
                      <Link style={{textDecoration: "none"}} to={"/reports/"+report.link}>
                        <Button startIcon={<FileCopyIcon/>} fullWidth variant="contained" color="primary" >{report.name}</Button>
                      </Link>
                    </Grid>
                  )
                })
              }
            </Grid>
          </div>
      )
    } else {
      return null;
    }
  }
}

export default connect()(withStyles(styles)(ReportOverviewWidget))
