import { combineReducers } from 'redux'

import reports from './reports'
import deviceGroups from './devicegroups'
import dashboard from './dashboard'
import alerts from './alerts'
import deviceAlerts from './deviceAlerts'
import maintenance from './maintenance'
import { devices, deviceSearch } from './devices'
// import deviceStorage from '../libs/deviceStorage'

function userSession(state = {
  logged_in: !!sessionStorage.jwt,
  properties: null,
  app_offline: false,
  jwt_token: null
}, action) {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return Object.assign({}, state, {
        logged_in: true
      })
    case 'LOGOUT_RECEIVE':
      localStorage.removeItem('jwt')
      return Object.assign({}, state, {
        logged_in: false,
        jwt_token: null
      })
    case 'SESSION_RECEIVE':
      return Object.assign({}, state, {
        app_offline: false,
        logged_in: true,
        properties: action.payload
      })
    case 'SESSION_FAILURE':
      return Object.assign({}, state, {
        app_offline: true,
        logged_in: false,
        properties: action.payload
      })
    case 'JWT_RECEIVE':
      localStorage.setItem('jwt', action.payload.jwt)
      // var token = deviceStorage.saveItem("jwt", action.payload.token);
      return Object.assign({}, state, {
        logged_in: true,
        jwt_token: action.payload.token
      })
    case 'JWT_REQUEST':
        return Object.assign({}, state, {
          login_error_msg: null
        })
    case 'JWT_FAILURE':
      return Object.assign({}, state, {
        login_error_msg: action.payload.response && action.payload.response.error !== ''
        ?
          action.payload.response.error
        :
          action.payload.statusText
      })
    default:
        return state
    }
}

const combinedReducer = combineReducers({
  devices,
  deviceSearch,
  deviceGroups,
  userSession,
  dashboard,
  alerts,
  deviceAlerts,
  reports,
  maintenance
})

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_RECEIVE') {
    state = undefined;
  }

  return combinedReducer(state, action);
};

export default rootReducer
