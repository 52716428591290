import { RSAA } from 'redux-api-middleware';
import { APIURL } from '../constants'

export const DEVICE_DASHBOARD_REQUEST = 'DEVICE_DASHBOARD_REQUEST'
export const DEVICE_DASHBOARD_SUCCESS = 'DEVICE_DASHBOARD_SUCCESS'
export const DEVICE_DASHBOARD_FAILURE = 'DEVICE_DASHBOARD_FAILURE'

export function initRequestDashboard(wan_dash_id, range) {
  var range_params = range ? 'start_epoch=' + range.from + '&end_epoch=' + range.to : ''
  var url = APIURL + '/api/v1/dashboards/dashboard/' + wan_dash_id + '/widgets/graphs?' + range_params
  console.log(url)
  return {
    [RSAA]: {
      endpoint: url,
      method: 'GET',
      types: [
        {
          type: DEVICE_DASHBOARD_REQUEST
          // meta: { 'goods': goods }
        },
        DEVICE_DASHBOARD_SUCCESS, DEVICE_DASHBOARD_FAILURE
      ],
      headers: {
        // "Authorization": "Basic dWtzcGFya3lAZ21haWwuY29tOnJhcmhvdi1kdXFoQXEtZmlxdmE1",
        // "Content-Type": "application/json",
      },
      // credentials: 'include'
    }
  }
}

export const DEVICE_GRAPHS_REQUEST = 'DEVICE_GRAPHS_REQUEST'
export const DEVICE_GRAPHS_SUCCESS = 'DEVICE_GRAPHS_SUCCESS'
export const DEVICE_GRAPHS_FAILURE = 'DEVICE_GRAPHS_FAILURE'

export function initRequestDashboardGraphs(wan_dash_id, range, { signal }) {
  var range_params = range ? 'start_epoch=' + range.from + '&end_epoch=' + range.to : ''
  var url = APIURL + '/api/v1/dashboards/dashboard/' + wan_dash_id + '/widgets/graphs?' + range_params
  return {
    [RSAA]: {
      endpoint: url,
      method: 'GET',
      types: [
        {
          type: DEVICE_GRAPHS_REQUEST
          // meta: { 'goods': goods }
        },
        DEVICE_GRAPHS_SUCCESS, DEVICE_GRAPHS_FAILURE
      ],
      headers: {
        // "Authorization": "Basic dWtzcGFya3lAZ21haWwuY29tOnJhcmhvdi1kdXFoQXEtZmlxdmE1",
        // "Content-Type": "application/json",
      },
      // credentials: 'include',
      options: { signal }
    }
  }
}


export const DASHBOARD_WIDGET_REQUEST = 'DASHBOARD_WIDGET_REQUEST'
export const DASHBOARD_WIDGET_SUCCESS = 'DASHBOARD_WIDGET_SUCCESS'
export const DASHBOARD_WIDGET_FAILURE = 'DASHBOARD_WIDGET_FAILURE'

export function initRequestWidgetData(widget_id) {
  return {
    [RSAA]: {
      endpoint: APIURL + '/api/v1/widgets/widget/' + widget_id + '/graph',
      method: 'GET',
      types: [
        {
          type: DASHBOARD_WIDGET_REQUEST
          // meta: { 'goods': goods }
        },
        DASHBOARD_WIDGET_SUCCESS, DASHBOARD_WIDGET_FAILURE
      ],
      headers: {
        // "Authorization": "Basic dWtzcGFya3lAZ21haWwuY29tOnJhcmhvdi1kdXFoQXEtZmlxdmE1",
        // "Content-Type": "application/json",
      },
      // credentials: 'include'
    }
  }
}

export const REPORT_DASHBOARD_REQUEST = 'REPORT_DASHBOARD_REQUEST'
export const REPORT_DASHBOARD_SUCCESS = 'REPORT_DASHBOARD_SUCCESS'
export const REPORT_DASHBOARD_FAILURE = 'REPORT_DASHBOARD_FAILURE'

export function getDashboardFromReportId(report_id) {
  if (!report_id) {
    return;
  }
  return {
    [RSAA]: {
      endpoint: APIURL + '/api/v1/reports/report/' + report_id + '/dashboard',
      method: 'GET',
      types: [
        {
          type: REPORT_DASHBOARD_REQUEST
          // meta: { 'goods': goods }
        },
        REPORT_DASHBOARD_SUCCESS, REPORT_DASHBOARD_FAILURE
      ],
      headers: {
        // "Authorization": "Basic dWtzcGFya3lAZ21haWwuY29tOnJhcmhvdi1kdXFoQXEtZmlxdmE1",
        // "Content-Type": "application/json",
      },
      // credentials: 'include'
    }
  }
}
