import React, { Component } from 'react'
import { connect } from 'react-redux'

import Typography from '@material-ui/core/Typography';

class PlaceholderContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  setTitle(title) {
    this.setState({ title: title });
  }

  componentDidMount() {
    this.props.setParentTitle('Some title here')
  }


  render() {
    // const { } = this.props
      return(
          <React.Fragment>
            <Typography>This is just a placeholder for future content..</Typography>
          </React.Fragment>
      )
  }
}

function mapStateToProps(state) {
  return { }
}

export default connect(mapStateToProps)(PlaceholderContainer)
