import React from 'react';
import { useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { getUnixTime } from 'date-fns';
import { initAddSDT } from '../../actions/maintenance';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function AddMaintenance({groups, devices}) {
  const [open, setOpen] = React.useState(false);
  const [comment, setComment] = React.useState(null);
  const [startTime, setStartTime] = React.useState(null)
  const [endTime, setEndTime] = React.useState(null)
  const [id, setId] = React.useState(null)
  const [type, setType] = React.useState('DeviceSDT');
  const [reduxProp, setReduxProp] = React.useState('label');
  const [targetSelection, setArray] = React.useState(devices)
  const dispatch = useDispatch();
  const classes = useStyles();

  const setSDTComment = (event) => {
    setComment(event.target.value)
    checkValuesAreChanged()
  }

  const addSDT = async () => {
    var prop
    var end = getUnixTime(new Date(endTime)) * 1000
    var start = getUnixTime(new Date(startTime)) * 1000
    if(type === 'DeviceSDT')
      prop = 'deviceId'
    else
      prop = 'deviceGroupId'
    dispatch(initAddSDT(start, end, comment, type, prop, id))
    handleClose()
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setStartTime(null);
    setEndTime(null);
    setId(null);
    setComment(null);
    setType('DeviceSDT');
    setReduxProp('label');
    setArray(devices);
    setOpen(false);
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
    setId(null)
    if(event.target.value === 'DeviceGroupSDT') {
      setReduxProp("name")
      setArray(groups)
    } else {
      setReduxProp("label")
      setArray(devices)
    }
  };

  const changeStartDate = (val) => {
    setStartTime(val)
    checkValuesAreChanged()
  }

  const changeEndDate = (val) => {
    setEndTime(val)
    setTimeout(checkValuesAreChanged, 5000)
  }

  const handleIdChange = (event) => {
    setId(event.target.value);
    checkValuesAreChanged()
  }

  const checkValuesAreChanged = () => {
    if (endTime !== null && startTime !== null && id !== null)
      return false
    else
      return true
  }

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        // className={buttonClassname}
        // disabled={is_loading}
        onClick={handleClickOpen}
      >
        Add Maintenance
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Schedule Planned Maintenance</DialogTitle>
        <DialogContent>
          <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="sdt-type-label">SDT Type</InputLabel>
            <Select
              labelId="sdt-type-label"
              id="sdt-type"
              value={type}
              onChange={handleTypeChange}
              label="SDT Type"
            >
              <MenuItem value={"DeviceSDT"}>Device</MenuItem>
              
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="target-label">Target</InputLabel>
            <Select
              labelId="target-label"
              id="target"
              value={id || ""}
              onChange={handleIdChange}
              label="Target"
            >
              {
                targetSelection.map((val, index) => {
                  return (
                    <MenuItem key={val.id} value={val.id}>{val[reduxProp]}</MenuItem>
                  )
                })
              }
            </Select>
          </FormControl>
          <Table size="small" aria-label="sdt time range" style={{paddingBottom: '10px'}}>
            <TableHead>
              <TableRow>
                <TableCell>Start</TableCell>
                <TableCell>End</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <form className={classes.container} noValidate>
                    <TextField
                      id="datetime-start"
                      type="datetime-local"
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => changeStartDate(e.target.value)}
                    />
                  </form>
                </TableCell>
                <TableCell>
                  <form className={classes.container} noValidate>
                    <TextField
                      id="datetime-end"
                      type="datetime-local"
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => changeEndDate(e.target.value)}
                    />
                  </form>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Add Comment"
            type="email"
            fullWidth
            variant="outlined"
            onChange={setSDTComment}
            placeholder="<FULL NAME> - <DESCRIPTION>"
            style={{paddingBottom: '10px'}}
          />
          <Button onClick={addSDT} variant="contained" color="primary" disabled={checkValuesAreChanged()}>
            Add
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          { /*
          <Button onClick={saveRecipients} color="primary">
            Save
          </Button>
          */ }
        </DialogActions>
      </Dialog>
    </div>
  );
}

