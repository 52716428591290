import React, { Component } from 'react'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
// import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import {
  // initRequestAlerts,
  initRequestRecipientGroupById, acknowledgeAlert } from '../actions/alerts'
import ConfigureRecipients from '../components/dialogs/ConfigureRecipients'
import AlertsTable from './AlertsTable'
import { getAlertInformation }  from '../actions/alerts'
import BackButton from '../components/BackButton';


class AlertsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: ''
    }
  }

  componentDidMount() {
    this.props.setParentTitle('Alerts')
    const params = new URLSearchParams(this.props.location.search);
    var filter = params.get('filter') || ""
    this.filterAlerts(filter);
    //this.props.dispatch(initRequestAlerts())
  }

  handleButtonClick () {
    this.props.dispatch(initRequestRecipientGroupById(this.props.account_id));
  }

  acknowledgeAlert() {
    this.props.dispatch(acknowledgeAlert(this.props.alert_id))
  }

  getAlertInfo(id) {
    this.props.dispatch(getAlertInformation(id))
  }

  filterAlerts (type) {
    this.setState({filter: type})
  }

  render() {
    const { alerts, account_id } = this.props
/*
    alerts.forEach((item) => {
      this.getAlertInfo(item.id)
    })
*/
    var filteredAlerts = [];
    if (this.state.filter) {
      this.props.alerts.alerts.forEach(alert => {
        if (alert.rule === this.state.filter) {
          filteredAlerts.push(alert)
        }
      })
    } else {
      filteredAlerts = alerts.alerts;
    }
    return(
      <React.Fragment>
      <BackButton link='/summary'/>
        <ConfigureRecipients account_id={account_id}/>
        <Grid container spacing={2} alignItems="flex-end" >
          <Grid item>

          </Grid>
          { /*
          <Grid item>
            <AddMaintenance/>
          </Grid>
          <Grid item>
            <Button variant="contained" disabled color="primary" startIcon={<DoneOutlineIcon />}>Acknowledge</Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="secondary" startIcon={<DoneOutlineIcon />}>Some alert action</Button>
          </Grid>
           */ }
        </Grid>
        <Grid container spacing={2} alignItems="flex-end" >
          <Grid item>
            <Button
              variant="outlined" color="primary"
              onClick={() => this.filterAlerts(null)}
            >
              ({ alerts.alerts ? alerts.alerts.length : 0}) Show All
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined" color="primary"
              onClick={() => this.filterAlerts('Critical')}
            >
              ({ alerts.byStatus['Critical'] ? alerts.byStatus['Critical'].length : 0}) Critical
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined" color="primary"
              onClick={() => this.filterAlerts('Error')}
            >
              ({ alerts.byStatus['Error'] ? alerts.byStatus['Error'].length : 0 }) Error
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined" color="primary"
              onClick={() => this.filterAlerts('Warning')}
            >
              ({ alerts.byStatus['Warning'] ? alerts.byStatus['Warning'].length : 0 }) Warning
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined" color="primary"
              onClick={() => this.filterAlerts('Acknowledged')}
            >
              ({ alerts.acknowledgedAlertIds.length }) Acknowledged
            </Button>
          </Grid>
        </Grid>
        <AlertsTable title={"Alerts"} items={filteredAlerts}/>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  const { alerts } = state
  const { account_id } = state.userSession.properties
  return { alerts, account_id }
}
export default connect(mapStateToProps)(AlertsContainer)
