import React, { useEffect, useRef } from 'react';
import { fromUnixTime, format } from 'date-fns'

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

/*
  Params:
    bool: disabled - initial state of the switch
    refreshFunction: function - function to run every auto-refresh
    seconds: integer - How often to run refreshFunction
    receivedAt: epoch - Time of last refresh time
*/
export default function PageRefreshInput({ disabled, refreshFunction, seconds, receivedAt }) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(true);
  const checkedRef = useRef(checked);
  checkedRef.current = checked

  useEffect(() => {
    if (disabled === true) {
      setChecked(false)
    }
    // setChecked(disabled ? !disabled : true)
    const timer = setInterval(() => {
      if (checkedRef.current === true) {
        if (refreshFunction) refreshFunction()
      }
    }, seconds ? seconds * 1000 : 60 * 1000);
    return () => clearInterval(timer);

  }, [refreshFunction, seconds, disabled]);

  const handleChange = name => event => {
    setChecked(!checkedRef.current)
  };

  const epochToTimestamp = epoch => {
    var date = fromUnixTime(epoch);
    return format(date, 'HH:mm:ss dd/MM/yy')
  }

  return (
    <div className={classes.root}>
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              checked={checkedRef.current}
              onChange={handleChange()}
              value="checked"
              color="primary"
            />
          }
          label={"Auto-refresh "}
        />
        <Typography style={{ padding: '9px' }} variant="body2">
          { receivedAt && '(Last updated: ' + epochToTimestamp(receivedAt) + ')'}
        </Typography>
      </FormGroup>
    </div>
  )
}
