import React, { Component } from 'react'
import { connect } from 'react-redux'

import AlertOverviewWidget from  '../components/widgets/AlertOverviewWidget'
import { initRequestAllDeviceGroups } from '../actions/devicegroup'

class AlertOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search_term: null,
    }
  }

  componentDidMount() {
    this.props.dispatch(initRequestAllDeviceGroups())
  }

  toggleRefresh() {
    console.log('toggling refresh')
    console.log(this.timer)
    this.timer ? this.disableRefresh() : this.enableRefresh()
  }

  enableRefresh() {
    this.timer = setInterval(() => {
      this.setDateRangeValues()
      this.updateGraphRanges()
    }, 60 * 1000) // 1 minute
  }

  disableRefresh() {
    clearTimeout(this.timer)
    this.timer = null
    console.log(this.timer)
  }

  render() {
    const { items } = this.props
    // console.log(this.props.byId);
    // if (this.props.byId) {
      return(
        <AlertOverviewWidget deviceGroups={items} />
      )
  //   } else {
  //     return(
  //       <CircularProgress/>
  //     )
  //   }
  }
}

// function mapStateToProps(state) {
//   const { items } = state.deviceGroups
//   return { items }
// }

export default connect()(AlertOverview)
