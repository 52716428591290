import React from 'react';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';

export default function AlertsIcon({ status, needsColour }) {
  var icon, colour
  if (status === 'Critical') {
    colour = needsColour ? colour = 'red' : colour = 'white'
    icon = <WhatshotIcon style={{ color: colour }} />
  } else if (status === 'Error') {
    colour = needsColour ? colour = 'red' : colour = 'white'
    icon = <ErrorIcon style={{ color: colour }} />
  } else if (status === 'Warning') {
    colour = needsColour ? colour = '#fc0' : colour = 'white'
    icon = <WarningIcon style={{ color: colour }} />
  } else if (status !== '') {
    colour = needsColour ? colour = 'green' : colour = 'white'
    icon = <DoneOutlineIcon style={{ color: colour }}/>
  } else {
    
  }
  return ( icon );
}
