import React from 'react';
import EmailIcon from '@material-ui/icons/Email';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import WarningIcon from '@material-ui/icons/Warning';

export default function AlertMethodIcon({ type, needsColour }) {
  var icon, colour
  if (type === 'EMAIL') {
    colour = needsColour ? colour = 'red' : colour = 'black'
    icon = <EmailIcon style={{ color: colour }} />
  } else if (type === 'SMS') {
    colour = needsColour ? colour = 'red' : colour = 'black'
    icon = <WarningIcon style={{ color: colour }} />
  } else if (type === 'PAGER') {
    colour = needsColour ? colour = '#fc0' : colour = 'black'
    icon = <WarningIcon style={{ color: colour }} />
  } else {
    colour = needsColour ? colour = 'green' : colour = 'black'
    icon = <DoneOutlineIcon style={{ color: colour }}/>
  }
  return ( icon );
}
