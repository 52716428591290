import convertArrayToObject from '../libs/utils'

export default function alerts(state = {
  alerts: [], recipient_group: null, byId: [], byDeviceId: []
}, action) {
  switch (action.type) {
    case 'ALERTS_SUCCESS':
      return Object.assign({}, state, {
        alerts: action.payload,
        byId: convertArrayToObject(action.payload, 'id'),
        byDeviceId: mapInstanceIdsToDeviceIds(action.payload),
        byStatus: groupAlertsByStatus(action.payload),
        acknowledgedAlertIds: acknowledgedAlertIds(action.payload)
      })
    case 'IDV_ALERT_SUCCESS':
      return Object.assign({}, state, {
        byId: storeAlertMessage(action.payload, state.byId)
      })
    case 'RECIPIENT_GROUPS_SUCCESS':
      return Object.assign({}, state, {
        recipient_group: action.payload
      })
    case 'ADD_RECIPIENT':
      return Object.assign({}, state, {
        recipient_group: addRecipient(state.recipient_group, action.Recipient)
      })
    case 'REMOVE_RECIPIENT':
      return Object.assign({}, state, {
        recipient_group: removeRecipient(state.recipient_group, action.Recipient)
      })
    default:
        return state
    }
}

function groupAlertsByStatus (alerts) {
  var byStatus = [];
  alerts.forEach((alert) => {
    if (!byStatus[alert.rule]) byStatus[alert.rule] = [];
    byStatus[alert.rule].push(alert.id);
    // mappings[item.monitorObjectId].push(item.id)
  })
  return Object.assign({}, byStatus);
}

function acknowledgedAlertIds (alerts) {
  var acknowledgedIds = [];
  alerts.forEach((alert) => {
    // if (!acknowledgedIds[alert.rule]) byStatus[alert.rule] = [];
    alert.acked && acknowledgedIds.push(alert.id);
    // mappings[item.monitorObjectId].push(item.id)
  })
  return acknowledgedIds;
}


function addRecipient (recipient_group, recipient) {
  // console.log(recipient_group)
  // var new_recipient_group = Object.create(recipient_group)
  recipient_group.recipients.push(recipient);

  var y = Object.assign({}, recipient_group);
  // console.log(y)
  // console.log(new_recipient_group)
  return y
  // return Object.create(recipient_group);
}


function removeRecipient (recipient_group, recipient_to_remove) {
  var filtered = recipient_group.recipients.filter((recipient, index, arr) => {
    return recipient_to_remove.addr !== recipient.addr;
  })
  // console.log(filtered)
  recipient_group.recipients = filtered;
  // console.log(recipient_group)
  var y = Object.assign({}, recipient_group);
  // console.log(y)
  return y;
}

function storeAlertMessage (alert, byId) {

  byId[alert.id].message = alert.detailMessage

  var y = Object.assign({}, byId);

  return y
}

function mapInstanceIdsToDeviceIds(array) {
  var mappings = {};
  array.forEach((item) => {
    mappings[item.monitorObjectId] = mappings[item.monitorObjectId] || []
    mappings[item.monitorObjectId].push(item.id)
  })
  return mappings
}
