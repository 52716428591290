import React, { Component } from 'react'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
// import DoneOutlineIcon from '@material-ui/icons/DoneOutline';

import {
  initRequestRecipientGroupById, acknowledgeAlert } from '../actions/alerts'
// import { initRequestAllDeviceGroups } from '../actions/devicegroup'
import { initRequestSDTs } from '../actions/maintenance'
import MaintenanceTable from './MaintenanceTable'
import AddMaintenance from '../components/dialogs/AddMaintenance'
import { getAlertInformation }  from '../actions/alerts'
import BackButton from '../components/BackButton';

class MaintenanceContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: null
    }
  }

  componentDidMount() {
    // const { device_group_id } = this.props
    this.props.setParentTitle('Maintenance')
    this.props.dispatch(initRequestSDTs())
    // device groups not used for maintenace just yet.
    // this.props.dispatch(initRequestAllDeviceGroups(device_group_id))
  }

  handleButtonClick () {
    this.props.dispatch(initRequestRecipientGroupById(this.props.account_id));
  }

  acknowledgeAlert() {
    this.props.dispatch(acknowledgeAlert(this.props.alert_id))
  }

  getAlertInfo(id) {
    this.props.dispatch(getAlertInformation(id))
  }

  filterAlerts (type) {
    this.setState({filter: type})
  }

  render() {
    const { sdts, byType, items, Devices } = this.props
/*
    alerts.forEach((item) => {
      this.getAlertInfo(item.id)
    })
*/
    var filteredSDTs = [];
    if (this.state.filter) {
      filteredSDTs = byType[this.state.filter]
    } else {
      filteredSDTs = sdts;
    }
    return(
      <React.Fragment>
        <BackButton link = '/summary'/>
        <Grid container spacing={2} alignItems="flex-end" >
          {
          <Grid item>
            <AddMaintenance groups={items} devices={Devices}/>
          </Grid> /*
          <Grid item>
            <Button variant="contained" disabled color="primary" startIcon={<DoneOutlineIcon />}>Acknowledge</Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="secondary" startIcon={<DoneOutlineIcon />}>Some alert action</Button>
          </Grid>
           */ }
        </Grid>
        <Grid container spacing={2} alignItems="flex-end" >
          <Grid item>
            <Button
              variant="outlined" color="primary"
              onClick={() => this.filterAlerts(null)}
            >
              ({ sdts ? sdts.length : 0}) Show All
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined" color="primary"
              onClick={() => this.filterAlerts('DeviceGroupSDT')}
            >
              ({ byType.DeviceGroupSDT ? byType.DeviceGroupSDT.length : 0}) Device Groups
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined" color="primary"
              onClick={() => this.filterAlerts('DeviceSDT')}
            >
              ({ byType.DeviceSDT ? byType.DeviceSDT.length : 0 }) Devices
            </Button>
          </Grid>
        </Grid>
        <MaintenanceTable title={"SDTs"} items={filteredSDTs} type={this.state.filter}/>
      </React.Fragment>
    )
  }
}


function mapStateToProps(state) {
  const { sdts } = state.maintenance
  const { byType } = state.maintenance
  const { items } = state.deviceGroups
  const { Devices } = state.deviceSearch
  const { device_group_id } = state.userSession.properties
  return { sdts, byType, items, Devices, device_group_id }
}
export default connect(mapStateToProps)(MaintenanceContainer)


/* TAKEN OUT AS NOT ESSENTIAL - HERE FOR FUTURE PROOFING
  <Grid item>
    <Button
      variant="outlined" color="primary"
      onClick={() => this.filterAlerts('DeviceDataSourceSDT')}
    >
      ({ byType.DeviceDataSourceSDT ? byType.DeviceDataSourceSDT.length : 0 }) DataSources
    </Button>
  </Grid>
  <Grid item>
    <Button
      variant="outlined" color="primary"
      onClick={() => this.filterAlerts('CollectorSDT')}
    >
      ({ byType.CollectorSDT ? byType.CollectorSDT.length : 0 }) Collectors
    </Button>
  </Grid>
 */
