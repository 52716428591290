import {
  RSAA,
  // isRSAA
} from 'redux-api-middleware'

export default function(store) {
  return function(next) {
    return function(action) {

      if (!action)
        return;
      const callApi = action ? action[RSAA] : null
      // Check if this action is a redux-api-middleware action.
      if (callApi) {
        // Inject the Authorization header from localStorage.
        const token = localStorage.getItem('jwt');
        if (token && token !== 'undefined') {
          callApi.headers = Object.assign({}, callApi.headers, {
            Authorization: "Bearer " + token || ''
          })
        }
      }
      // Pass the FSA to the next action.
      return next(action)
    }
  }
}
