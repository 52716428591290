import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
    marginBottom: 40
  },
}));

const DeviceSummary = ({ Device }) => {
  const classes = useStyles();

  if (Device) {
    return (
        <Paper className={classes.root}>
          {
            /*
          <Typography variant="h6">
            Device Info
          </Typography>
          */
          }
          <Grid container spacing={1}>
            <Grid item xs={4} sm={2}>
              <Typography >
                Customer:
              </Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Typography >
                {Device['Customer Name']}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={2}>
              <Typography >
                Device:
              </Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Typography >
                {Device.label}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={2}>
              <Typography >
                Type:
              </Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Typography >
                {Device['auto.cisco.chassis_model'] || '-'}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={2}>
              <Typography >
                Postcode:
              </Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Typography >
                {Device['postcode'] || '-'}
              </Typography>
            </Grid>
            { /*
            <Grid item xs={2}>
              <Typography variant="overline">
                FQDN:
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1">
                {Device.fqdn || '-'}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="overline">
                IP:
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body1">
                {Device.name || '-'}
              </Typography>
            </Grid>
            */ }
            <Grid item xs={4} sm={2}>
              <Typography >
                Circuit Reference:
              </Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Typography >
                {Device['circuit reference'] || '-'}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={2}>
              <Typography >
                Provider:
              </Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Typography >
                {Device['circuit reference provider'] || '-'}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={2}>
              <Typography >
                Circuit Size:
              </Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Typography >
                {Device['cctCIR'] || '-'}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
    );
  } else {
    return null;
  }
}

export default DeviceSummary
