import React from 'react';
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import AlertsIcon from './AlertsIcon'

const useStyles = makeStyles(theme => ({
  card: {
    // display: 'flex',
    // alignItems: 'center'
  },
  title: {
    textAlign: 'center'
  },
}));

export default function SummaryStat({ tile, noicon }) {
  const classes = useStyles();

  React.useEffect(() => {
    return () => {

    };
  }, []);

  return (
    <Grid item sm={4} md={4} lg={3} xl={3}>
      { tile.link ?
        <Link style={{textDecoration: "none"}} to={tile.link}>
          <Card  onClick={() => tile.handleClick && tile.handleClick() } md={4} lg={3} xl={3} className={classes.card}>
            <CardContent className={classes.title} justify = "center">
              { !noicon && <AlertsIcon needsColour status={tile.status}/> }
              <Typography color="textSecondary" gutterBottom>
                { tile.title || 'No title set' }
              </Typography>
              <Typography  variant="h2" component="h2">
                { tile.count || 0 }
              </Typography>
            </CardContent>
          </Card>
        </Link>
        :
        <Card  onClick={() => tile.handleClick && tile.handleClick() } md={4} lg={3} xl={3} className={classes.card}>
          <CardContent className={classes.title} justify = "center">
          { !noicon && <AlertsIcon needsColour status={tile.status}/> }
            <Typography color="textSecondary" gutterBottom>
              { tile.title || 'No title set' }
            </Typography>
            <Typography  variant="h2" component="h2">
              { tile.count || 0 }
            </Typography>
          </CardContent>
        </Card>
      }
    </Grid>
  );
}
