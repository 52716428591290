import React from 'react';
import { Provider } from 'react-redux'
import Amplify from 'aws-amplify';
// import { withAuthenticator } from 'aws-amplify-react';
// import { AmplifyAuthenticator } from '@aws-amplify/ui-react';

import { IS_EMBEDDED } from './constants'
import awsconfig from './aws-exports';
import configureStore from './configureStore'
import AuthContainer from './containers/AuthContainer'
import AWSAuthContainer from './containers/AWSAuthContainer'

Amplify.configure(awsconfig);

var initialState
const store = configureStore(initialState);

export default function App() {
  return (
    <Provider store={store}>
      {
        IS_EMBEDDED === true ?
          <AuthContainer />
          :
          <AWSAuthContainer />
      }

    </Provider>
  );
}

// export default withAuthenticator(App, {includeGreetings: true});
