import React, { Component } from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Auth } from 'aws-amplify';
import { BrowserRouter as Router, Redirect, Route, Link as RouterLink } from "react-router-dom";
import { withStyles, createStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { ThemeProvider } from '@material-ui/core/styles';
import { daisyTheme } from "@akj-dev/design-system";

import DeviceContainer from './DeviceContainer'
import DevicesContainer from './DevicesContainer'
import DashboardContainer from './DashboardContainer'
import GraphsContainer from  './GraphsContainer'
import DeviceGroupContainer from  './DeviceGroupContainer'
import AlertsContainer from  './AlertsContainer'
import MaintenanceContainer from './MaintenanceContainer'
import ReportsContainer from  './ReportsContainer'
import SummaryStats from './SummaryStats'
import LoginContainer from  './LoginContainer'
import PlaceholderContainer from  './PlaceholderContainer'
// import AvailabilityContainer from './RunReportContainer'

import SideMenu from './SideMenu'

import { initRequestSession, fetchJWT, logoutUser} from '../actions/session';
import { initRequestAlerts } from '../actions/alerts'
// import {  getDashboardFromReportId } from '../actions/dashboard';
import { initDeviceSearch } from '../actions/devices';
import { initRequestAllDeviceGroups } from '../actions/devicegroup'
import { initRequestReports } from '../actions/reports'
import RunReportContainer from './RunReportContainer';


const drawerWidth = 240;

const useStyles = (theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    content: {
      flexGrow: 1,
      overflow: 'auto',
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }
  });

function ListItemLink(props) {
  const { icon, primary, to } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        // With react-router-dom@^6.0.0 use `ref` instead of `innerRef`
        // See https://github.com/ReactTraining/react-router/issues/6056
        <RouterLink to={to} {...itemProps} innerRef={ref} />
      )),
    [to],
  );

  return (
    <li>
      <ListItem button component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

ListItemLink.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

// const useStyles = makeStyles({
//   root: {
//     width: 360,
//   },
// });

class AppContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      loading: true,
      is_offline: false,
      logged_in: false,
      open: false,
      data_loaded: false,
      wan_group_dash_id: null
    }
  }


  componentDidMount() {
    // this.initialDataLoad()
  }

  async initialDataLoad() {
    // const session = await this.props.initRequestSession()
    // if (session.type && session.type !== 'SESSION_FAILURE') {
    // this.props.dispatch(initRequestDashboard(dash_id))
    // this.props.initRequestAllDeviceGroups()
    // this.setState({ wan_group_dash_id: session.payload.wan_group_dash_id })
      // this.checkExistingSession()

      // Switching back to wan view dash...
      await this.props.initDeviceSearch()
      await this.props.initRequestAlerts()
      await this.props.initRequestReports()

    //   this.setState({ is_offline: false, logged_in: true })
    // } else {
    //   this.setState({ logged_in: false })
    // }
    this.setState({ loading: false })
  }

  async checkExistingSession() {
    const jwt_token = localStorage.getItem('jwt');
    if (!jwt_token) {
      try {
        // updating users account_id
        // const user = await Auth.currentAuthenticatedUser()
        // const result = await Auth.updateUserAttributes(
        //     user,
        //     { "custom:account_id": "RHF06013"
        // })
        // console.log(result);

        const session = await Auth.currentSession();
        if (session) {
          console.log(session);
          localStorage.setItem('jwt', session.idToken.jwtToken);
          this.setState({ is_offline: false, logged_in: true })
        }

      } catch (error) {
        console.log('error finding a session: ', error);
        this.setState({ logged_in: false })
      }
    }
    if (jwt_token) {
      const session = await this.props.initRequestSession()
      if (session.type && session.type !== 'SESSION_FAILURE') {
        this.setState({ is_offline: false, logged_in: true })
      } else {
        console.log('problem loading session from token');
        localStorage.removeItem('jwt');
      }
    }

    this.setState({ loading: false })
  }

  handleDrawerOpen() {
    this.setState({open: true});
  }

  handleDrawerClose() {
    this.setState({open: false});
  }

  setTitle(title) {
    this.setState({ title: title });
  }

  async logoutUser() {

    try {
      await Auth.signOut();
    } catch (error) {
      console.log('error signing out: ', error);
    }
    this.props.logoutUser();
  }

  render() {
    var { classes } = this.props
    // if (this.props.logged_in === false) {
    //   return (
    //       <LoginContainer setParentTitle={(title) => this.setTitle(title)}/>
    //   )
    // } else {
      return (
        <Router>
          <ThemeProvider theme={daisyTheme}>
            <div className={classes.root} style={{ height: '100%', position: 'absolute', left: '0px', width: '100%', overflow: 'hidden'}}>
              <CssBaseline />
              <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                  [classes.appBarShift]: this.state.open,
                })}
              >
                <Toolbar>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => this.handleDrawerOpen()}
                    edge="start"
                    className={clsx(classes.menuButton, this.state.open && classes.hide)}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Typography variant="h6" noWrap>
                    {this.state.title}
                  </Typography>
                </Toolbar>
              </AppBar>

              <SideMenu
                open={this.state.open}
                width={drawerWidth}
                closedrawer={() => this.handleDrawerClose()}
                authenticated={this.props.logged_in}
                logoutUser={() => this.logoutUser()}
              />

              <main
                className={clsx(classes.content, {
                  [classes.contentShift]: this.state.open,
                })}
              >
                <div className={classes.drawerHeader} />
                  <div className="App">
                    <Container maxWidth="lg" className={classes.container}>
                      <Route exact path="/" render={(props) => <SummaryStats {...props} setParentTitle={(title) => this.setTitle(title)} />} />
                      <Route exact path="/services/connectivity" render={(props) => <DevicesContainer {...props} setParentTitle={(title) => this.setTitle(title)} />} />
                      <Route exact path="/services/network" render={(props) => <PlaceholderContainer {...props} setParentTitle={(title) => this.setTitle(title)} />} />
                      <Route exact path="/services/servers" render={(props) => <PlaceholderContainer {...props} setParentTitle={(title) => this.setTitle(title)} />} />
                      <Route exact path="/services/application" render={(props) => <PlaceholderContainer {...props} setParentTitle={(title) => this.setTitle(title)} />} />
                      <Route exact path="/services/floorplans" render={(props) => <PlaceholderContainer {...props} setParentTitle={(title) => this.setTitle(title)} />} />
                      <Route exact path="/services" render={(props) => <PlaceholderContainer {...props} setParentTitle={(title) => this.setTitle(title)} />} />
                      <Route path="/dashboard" render={(props) => <DashboardContainer {...props} wan_group_dash_id={this.state.wan_group_dash_id} setParentTitle={(title) => this.setTitle(title)} />} />
                      <Route path="/dashboard/:id" render={(props) => <DashboardContainer {...props} setParentTitle={(title) => this.setTitle(title)} />} />
                      <Route path="/device/:id" render={(props) => <DeviceContainer {...props} setParentTitle={(title) => this.setTitle(title)} />}  />
                      <Route path="/graphs/:id" render={(props) => <GraphsContainer {...props} setParentTitle={(title) => this.setTitle(title)} />}  />
                      <Route path="/groups/:id" render={(props) => <DeviceGroupContainer {...props} setParentTitle={(title) => this.setTitle(title)} />} />
                      <Route path="/alerts" render={(props) => <AlertsContainer {...props} windowRef={this.props.windowRef} setParentTitle={(title) => this.setTitle(title)} />} />
                      <Route path="/maintenance" render={(props) => <MaintenanceContainer {...props} setParentTitle={(title) => this.setTitle(title)} />} />
                      <Route exact path="/reports" render={(props) => <ReportsContainer {...props} setParentTitle={(title) => this.setTitle(title)} />} />
                      <Route path="/reports/:id" render={(props) => <RunReportContainer {...props} setParentTitle={(title) => this.setTitle(title)} />} />
                      <Route path="/summary" render={(props) => <SummaryStats {...props} setParentTitle={(title) => this.setTitle(title)} />} />
                      <Route path="/login" render={(props) => <LoginContainer {...props} setParentTitle={(title) => this.setTitle(title)} />} />
                      <Redirect to="/" />
                    </Container>
                  </div>
              </main>
            </div>
          </ThemeProvider>
        </Router>
      )
    // }
  }
}

const mapDispatchToProps = {
  initRequestSession,
  fetchJWT,
  logoutUser,
  initRequestAllDeviceGroups,
  initDeviceSearch,
  initRequestAlerts,
  initRequestReports
}

const mapStateToProps = (state) => {
  var { logged_in } = state.userSession
  return {
    logged_in
  }
};


const enhance = compose(
    withStyles(useStyles),
    connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(AppContainer)
