import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';

// import { acknowledgeAlert} from '../actions/alerts'

import AlertRow from './AlertRow'
import AcknowledgeDialog from '../components/dialogs/AcknowledgeAlert'

function desc(a, b, orderBy) {
  if (a[orderBy] === undefined) a[orderBy] = ''
  if (b[orderBy] === undefined) b[orderBy] = ''
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headCells = [
  { id: 'rule', numeric: false, disablePadding: false, label: 'Severity' },
  { id: 'start_date', numeric: false, disablePadding: false, label: 'Alert Began' },
  { id: 'request-id', numeric: false, disablePadding: false, label: 'Billing Reference' },
  { id: 'customer_name', numeric: false, disablePadding: false, label: 'Site Name' },
  { id: 'postcode', numeric: false, disablePadding: false, label: 'Postcode' },
  { id: 'circuit_ref', numeric: false, disablePadding: false, label: 'Circuit Reference' },
  { id: 'instanceName', numeric: false, disablePadding: false, label: 'Instance' },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort, type } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  if (type === 'ack') {
    headCells.push({
      id: 'ackedBy',
      numeric: false,
      disablePadding: false,
      label: 'Acknowledged By'
    })
  }
  else if (headCells.length === 8) {
    headCells.pop()
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'null' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>

        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    minHeight: 0
},
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();

  return (
    <Toolbar
      className={clsx(classes.root)}
    >
      <div className={classes.title}>

      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>

      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {

};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  card: {
    width: '100%'
  },
  table: {
    width: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  row: {
    cursor: 'pointer'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function AlertsTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('start_date');
  const [selected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [dialogState, setDialogState] = React.useState(false);
  const [alertRow, setAlertRow] = React.useState({
    id: "-",
    threshold: "-",
    alertValue: "-",
    dataPointName: "-",
    message: {
      subject: "Subject",
      body: "Message"
    }
  });
  var rows = props.items || [];

  function handleRequestSort(event, property) {
    console.log(property)
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function closeDialog() {
    console.log('closing dialog..');
    setDialogState(false)
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  var newer = [], ack = [], sdt = [];
  var inEither;

  for(var x=0; x<rows.length; x++) {
    inEither = false;

    if(rows[x]['acked'] === true) {
      ack.push(rows[x]);
      inEither = true;
    }
    else if(rows[x]['sdted'] === true) {
      sdt.push(rows[x]);
      inEither = true;
    }

    if(!inEither) newer.push(rows[x])
  }

  return (
    <div className={classes.root}>
      <AcknowledgeDialog alert={alertRow} state={dialogState} closeDialog={closeDialog}/>
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size='small'
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={newer.length}
              type="new"
            />
            <TableBody>
              {
                stableSort(newer, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {

                  return (
                    <AlertRow key={row.id} row={row} state={true} setAlertRow={setAlertRow}></AlertRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
