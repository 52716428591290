import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withStyles, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import BackButton from '../components/BackButton'

import getUnixTime from 'date-fns/getUnixTime'
import { daysUptime } from '../libs/utils'

import DeviceSummary from '../components/DeviceSummary'
import SummaryStat from '../components/SummaryStat'
import GraphsContainer from './GraphsContainer'
import DeviceAlerts from '../components/DeviceAlerts'

import { initRequestDashboardGraphs, initRequestWidgetData } from '../actions/dashboard'

const styles = (theme) =>
  createStyles({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 400,
    },
    root: {
      padding: theme.spacing(1),
      flexGrow: 1
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary
    }
  });

class DeviceContainer extends Component {
  constructor(props) {
    super(props);
    var today = new Date();
    var yesterday = new Date(new Date().setDate(new Date().getDate()-1));
    this.state = {
      is_hidden: true,
      loadingWidgets: false,
      rangesChanged: false,
      fromDate: yesterday,
      fromEpoch: getUnixTime(yesterday),
      toDate: today,
      toEpoch: getUnixTime(today),
      wan_dash_id: props.wan_dash_id
    }
    this.timer = null;
  }

  componentDidMount() {
    var Device = this.props.location.state && this.props.location.state.Device;
    Device = !Device  && this.props.Device
    this.props.setParentTitle(Device.label)
    this.updateGraphRanges();
    // ranges don't change on mount - we just simulate that.
    this.setState({ rangesChanged: false })
  }

  componentWillUnmount() {

  }

  setDateRangeValues() {
    var today = new Date();
    var yesterday = new Date(new Date().setDate(new Date().getDate()-1));

    this.handleFromDateChange(yesterday)
    this.handleToDateChange(today)
  }

  handleFromDateChange = date => {
    var hasChanged = false;
    if (this.state.fromEpoch !== getUnixTime(date)) {
        hasChanged = true;
    }
    this.setState({
      rangesChanged: hasChanged,
      fromDate: date,
      fromEpoch: getUnixTime(date)
    });
  };

  handleToDateChange = date => {
    var hasChanged = false;
    if (this.state.fromEpoch !== getUnixTime(date)) {
        hasChanged = true;
    }
    this.setState({
      rangesChanged: hasChanged,
      toDate: date,
      toEpoch: getUnixTime(date)
    });
  };

  async updateGraphRanges () {
    this.setState({ loadingWidgets: true })
    // await this.props.initRequestDashboardGraphs(this.props.match.params.id, range)
    this.setState({ loadingWidgets: false, rangesChanged: false })
  }

  render() {
    const { classes } = this.props;
    var Device = this.props.location.state && this.props.location.state.Device;
    var Alerts = this.props.device_alerts || []
    if (!Device) {
      Device = this.props.Device
      // get the device from match params id
    }
    return(
        <div className={classes.root}>
          <BackButton link = '/services/connectivity/'/>
          <DeviceSummary Device={Device}/>
          <Grid container spacing={2}>
            <SummaryStat noicon tile={{ title: 'Device Power Uptime', count: daysUptime(Device.upTimeInSeconds) }}/>
            <DeviceAlerts Alerts={Alerts}/>
          </Grid>
          <GraphsContainer Device={Device} style={{ paddingTop: '20px' }}/>
        </div>
      )
  }

}


function mapStateToProps(state, ownProps) {
  const { graphs, wan_dash_id } = state.dashboard
  const Device = state.devices.byId && state.devices.byId[ownProps.match.params.id];
  const alertIds = state.alerts.byDeviceId.length > 0 ? state.alerts.byDeviceId[ownProps.match.params.id] : []

  const device_alerts = []
  alertIds.forEach(id => {
    device_alerts.push(state.alerts.byId[id])
  })
  return { graphs, wan_dash_id, Device, device_alerts }
}

const mapDispatchToProps = {
  initRequestDashboardGraphs,
  initRequestWidgetData
}

const enhance = compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(DeviceContainer)
