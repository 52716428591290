import { RSAA } from 'redux-api-middleware';
import { APIURL } from '../constants'

export const REQUEST = 'REQUEST_DEVICE'
export const RECEIVE = 'RECEIVE_DEVICE'
export const FAILURE = 'FAILURE_DEVICE'

export function initRequestDevice() {
  return {
    [RSAA]: {
      endpoint: APIURL + '/api/v1/device', // url,
      method: 'GET',
      types: [
        {
          type: REQUEST
          // meta: { 'goods': goods }
        },
        RECEIVE, FAILURE
      ],
      headers: {
        // "Authorization": "Basic dWtzcGFya3lAZ21haWwuY29tOnJhcmhvdi1kdXFoQXEtZmlxdmE1",
        // "Content-Type": "application/json",
      },
      //credentials: 'include'
    }
  }
}

export function resetDeviceSearch() {
  return {
    type: 'DEVICE_SEARCH_RESET'
  }
}

export const REQUEST_DEVICE_ALERTS = 'REQUEST_DEVICE_ALERTS'
export const RECEIVE_DEVICE_ALERTS = 'RECEIVE_DEVICE_ALERTS'
export const FAILURE_DEVICE_ALERTS = 'FAILURE_DEVICE_ALERTS'

export function initRequestDeviceAlerts(id) {
  return {
    [RSAA]: {
      endpoint: APIURL + '/device/' + id + '/alerts', // url,
      method: 'GET',
      types: [
        {
          type: REQUEST_DEVICE_ALERTS
          // meta: { 'goods': goods }
        },
        RECEIVE_DEVICE_ALERTS, FAILURE_DEVICE_ALERTS
      ],
      headers: {
        // "Authorization": "Basic dWtzcGFya3lAZ21haWwuY29tOnJhcmhvdi1kdXFoQXEtZmlxdmE1",
        // "Content-Type": "application/json",
      },
      // credentials: 'include'
    }
  }
}


export const DEVICE_SEARCH_REQUEST = 'DEVICE_SEARCH_REQUEST'
export const DEVICE_SEARCH_SUCCESS = 'DEVICE_SEARCH_SUCCESS'
export const DEVICE_SEARCH_FAILURE = 'DEVICE_SEARCH_FAILURE'

export function initDeviceSearch(search_term) {
  var url = APIURL + "/api/v1/devices"
  if (search_term != null)
    url += ("?search_term=" + search_term)
    
  return {
    [RSAA]: {
      endpoint: url,
      method: 'GET',
      types: [
        {
          type: DEVICE_SEARCH_REQUEST
        },
        DEVICE_SEARCH_SUCCESS, DEVICE_SEARCH_FAILURE
      ],
      headers: {

      }
      // credentials: 'include'
    }
  }
}


export const REQUEST_DEVICE_GRAPHS = 'REQUEST_DEVICE_GRAPHS'
export const RECEIVE_DEVICE_GRAPHS = 'RECEIVE_DEVICE_GRAPHS'
export const FAILURE_DEVICE_GRAPHS = 'FAILURE_DEVICE_GRAPHS'

export function initRequestDeviceGraphs(id) {
  return {
    [RSAA]: {
      endpoint: APIURL + '/device/' + id + '/graphs', // url,
      method: 'GET',
      types: [
        {
          type: REQUEST_DEVICE_GRAPHS
          // meta: { 'goods': goods }
        },
        RECEIVE_DEVICE_GRAPHS, FAILURE_DEVICE_GRAPHS
      ],
      headers: {
        // "Authorization": "Basic dWtzcGFya3lAZ21haWwuY29tOnJhcmhvdi1kdXFoQXEtZmlxdmE1",
        // "Content-Type": "application/json",
      },
      credentials: 'include'
    }
  }
}
