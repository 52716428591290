import { RSAA } from 'redux-api-middleware';
import { APIURL } from '../constants'

export const ALERTS_REQUEST = 'ALERTS_REQUEST'
export const ALERTS_SUCCESS = 'ALERTS_SUCCESS'
export const ALERTS_FAILURE = 'ALERTS_FAILURE'

export function initRequestAlerts() {
  var url = APIURL + '/api/v1/alerts?customColumns=Customer%20Name,postcode,circuit%20reference'
  return {
    [RSAA]: {
      endpoint: url,
      method: 'GET',
      types: [
        {
          type: ALERTS_REQUEST
        },
        ALERTS_SUCCESS, ALERTS_FAILURE
      ],
      headers: {

      },
      // credentials: 'include'
    }
  }
}

export const RECIPIENT_GROUPS_REQUEST = 'RECIPIENT_GROUPS_REQUEST'
export const RECIPIENT_GROUPS_SUCCESS = 'RECIPIENT_GROUPS_SUCCESS'
export const RECIPIENT_GROUPS_FAILURE = 'RECIPIENT_GROUPS_FAILURE'

export function initRequestRecipientGroupById(id) {
  var url = APIURL + '/api/v1/recipient_groups/group/' + id
  return {
    [RSAA]: {
      endpoint: url,
      method: 'GET',
      types: [
        {
          type: RECIPIENT_GROUPS_REQUEST
        },
        RECIPIENT_GROUPS_SUCCESS, RECIPIENT_GROUPS_FAILURE
      ],
      headers: {

      },
      // credentials: 'include'
    }
  }
}

export const ADD_RECIPIENT = 'ADD_RECIPIENT'
export const REMOVE_RECIPIENT = 'REMOVE_RECIPIENT'

export function initRemoveRecipient(Recipient) {
  return {
    type: REMOVE_RECIPIENT,
    Recipient: Recipient,
    requestedTime: Date.now()
  }
}

export function initAddRecipient(Recipient) {
  return {
    type: ADD_RECIPIENT,
    Recipient: Recipient,
    requestedTime: Date.now()
  }
}

export const SAVE_RECIPIENTS_REQUEST = 'SAVE_RECIPIENTS_REQUEST'
export const SAVE_RECIPIENTS_SUCCESS = 'SAVE_RECIPIENTS_SUCCESS'
export const SAVE_RECIPIENTS_FAILURE = 'SAVE_RECIPIENTS_FAILURE'

export function initSaveRecipients(RecipientGroup) {
  var url = APIURL + '/api/v1/recipient_groups/group/' + RecipientGroup.id
  return {
    [RSAA]: {
      endpoint: url,
      method: 'PUT',
      body: JSON.stringify(RecipientGroup),
      types: [
        {
          type: RECIPIENT_GROUPS_REQUEST
        },
        RECIPIENT_GROUPS_SUCCESS, RECIPIENT_GROUPS_FAILURE
      ],
      // credentials: 'include'
    }
  }
}

export const ACKNOWLEDGE_REQUEST = 'ACKNOWLEDGE_REQUEST'
export const ACKNOWLEDGE_SUCCESS = 'ACKNOWLEDGE_SUCCESS'
export const ACKNOWLEDGE_FAILURE = 'ACKNOWLEDGE_FAILURE'

export function acknowledgeAlert(alertId, comment) {
  var url = APIURL + '/api/v1/alerts/alert/' + alertId + '/ack'
  return {
    [RSAA]: {
      endpoint: url,
      method: 'POST',
      types: [
        {
          type: ACKNOWLEDGE_REQUEST
        },
        ACKNOWLEDGE_SUCCESS, ACKNOWLEDGE_FAILURE
      ],
      headers: {

      },
      body: JSON.stringify({ackComment: comment})
    }
  }
}

export const IDV_ALERT_REQUEST = 'IDV_ALERT_REQUEST'
export const IDV_ALERT_SUCCESS = 'IDV_ALERT_SUCCESS'
export const IDV_ALERT_FAILURE = 'IDV_ALERT_FAILURE'

export function getAlertInformation(alertId) {
  var url = APIURL + '/api/v1/alerts/alert/' + alertId
  return {
    [RSAA]: {
      endpoint: url,
      method: 'GET',
      types: [
        {
          type: IDV_ALERT_REQUEST
        },
        IDV_ALERT_SUCCESS, IDV_ALERT_FAILURE
      ],
      headers: {

      }
    }
  }
}