import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import { animateScroll as scroll } from 'react-scroll'


import AlertsIcon from '../components/AlertsIcon'

function desc(a, b, orderBy) {
  if (a[orderBy] === undefined) a[orderBy] = ''
  if (b[orderBy] === undefined) b[orderBy] = ''
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}


function sortStatuses() {
  console.log('sorting inside sortStatuses')
}

const headCells = [
  { id: 'status', numeric: false, disablePadding: false, label: 'Status', sortFunction:  sortStatuses},
  { id: 'Site Name', numeric: false, disablePadding: false, label: 'Site Name' },
  { id: 'postcode', numeric: false, disablePadding: false, label: 'Post Code' },
  { id: 'request-id', numeric: false, disablePadding: false, label: 'Billing Reference' },
  { id: 'name', numeric: false, disablePadding: false, label: 'IP' },
  { id: 'circuit reference', numeric: false, disablePadding: false, label: 'Circuit Ref' },
  { id: 'auto.cisco.chassis_model', numeric: false, disablePadding: false, label: 'Device Type' },
  { id: 'circuit reference provider', numeric: false, disablePadding: false, label: 'Circuit Provider' },
  { id: 'cctCIR', numeric: true, disablePadding: false, label: 'Circuit Size' }
];



function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = headCell => event => {
    console.log(headCell)
    // @todo fix this test, it's not right.
    // if (headCell.sortFunction) {
    //   console.log('sorting by status...')
    // } else {
    //   console.log('standard sorting..')
      onRequestSort(event, headCell.id);
    // }
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  row: {
    cursor: 'pointer'
  }
}));

export default function EnhancedTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('status');
  const [selected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const rows = props.items || [];

  React.useEffect(() => {
      scroll.scrollToTop();
    }, [rowsPerPage]);

  function handleRequestSort(event, property) {
    console.log(property)
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleClick(event, row) {
    props.clickRow(row);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size='small'
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {
                rows.forEach(row => {
                  // items.s.some(el => ids.includes(el)))
                  if (row.hostStatus === 'dead') {
                    row.status = '0-critical'
                  } else if (props.devicesInError && props.devicesInError.includes(row.id)) {
                    // if (row.alertStatus === 'unconfirmed-critical-none') {
                      // row.status = '0-critical'
                    // } else if (row.alertStatus === 'unconfirmed-error-none') {
                      // row.status = '1-error'
                    // } else if (row.alertStatus === 'unconfirmed-warn-none') {
                    row.status = '2-warning'
                  } else {
                    row.status = '3-ok'
                    // }
                    // console.log(props.devicesInError)
                    // console.log(row.id)
                  }
                })
              }
              {
                stableSort(rows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  var icon = "-"
                  if (row.status === '0-critical') {
                    icon = <WhatshotIcon/>
                    icon = <AlertsIcon needsColour status={"Critical"}/>
                  } else if (row.status === '1-error') {
                    icon = <ErrorIcon/>
                    icon = <AlertsIcon needsColour status={Error}/>
                  } else if (row.status === '2-warning') {
                    icon = <WarningIcon/>
                    icon = <AlertsIcon needsColour status={"Warning"}/>
                  } else {
                    icon = <AlertsIcon needsColour/>
                  }
                  return (
                    <TableRow
                      hover
                      onClick={event => handleClick(event, row)}
                      role="checkbox"
                      tabIndex={-1}
                      key={row.name}
                      className={classes.row}
                    >
                      <TableCell align="left">
                        {icon}
                      </TableCell>
                      <TableCell align="left">
                        {row['Customer Name']}
                      </TableCell>
                      <TableCell align="left" component="th" id={labelId} scope="row">
                        {row['postcode']}
                      </TableCell>
                      <TableCell align="left">{row['request-id']}</TableCell>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left">{row['circuit reference']}</TableCell>
                      <TableCell align="left">{row['auto.cisco.chassis_model']}</TableCell>
                      <TableCell align="left">{row['circuit reference provider']}</TableCell>
                      <TableCell align="left">{row['cctCIR']}</TableCell>
                    </TableRow>
                  );
                })
              }
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100, 1000]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
