import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import DevicesIcon from '@material-ui/icons/Devices';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { withStyles } from '@material-ui/core/styles';

import AlertsIcon from '../AlertsIcon'


const styles = theme => ({
  root: {
    // padding: theme.spacing(3, 2),
    height: 90,
    overflow: 'hidden',
    padding: theme.spacing(2),
    color: 'white',
    cursor: 'pointer'
  },
  paper: {
    padding: theme.spacing(2)
  },
  Critical: {
      backgroundColor: 'red'
  },
  confirmedWarnAlert: {
    backgroundColor: 'blue'
  },
  Warning: {
    backgroundColor: 'orange'
  },
  confirmedErrorAlert: {
    backgroundColor: 'yellow'
  },
  confirmedCriticalAlert: {
    backgroundColor: 'blue'
  },
  noAlert: {
    backgroundColor: 'green'
  },
  iconbar: {
    width: 'fit-content'
  }
});


class AlertOverviewWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      group_selected: false,
    }
  }

  componentDidMount() {
  }

  compare(a, b) {
    // console.log(a)
    // console.log(b)
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();

    let comparison = 0;
    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }
    return comparison;
  }


  clickDeviceGroup = (id) => {
    // this.setState({group_selected: true})
    // this.props.dispatch(initRequestDeviceGroup(id))
    console.log('clicky for ' + id);
    this.loadDashboard(id)
  }

  orderDeviceGroups(deviceGroups) {

    let criticalGroups = [], warnGroups = [], ackWarnGroups = [], alertGroups = [], allGroups = [];
    deviceGroups.map((deviceGroup, index) => {
      if (deviceGroup.alert_status.match(/critical/)) {
        deviceGroup.alertStatus = 'Critical'
        criticalGroups.push(deviceGroup)
      } else if (deviceGroup.alert_status.match(/warn/)) {
        deviceGroup.alertStatus = 'Warning'
        warnGroups.push(deviceGroup)
      } else if (deviceGroup.confirmedWarnAlert) {
        deviceGroup.alertStatus = 'noAlert'
        ackWarnGroups.push(deviceGroup)
      } else {
        deviceGroup.alertStatus = 'noAlert'
        alertGroups.push(deviceGroup)
      }
    //   // console.log(deviceGroup)
      // return null
    //   // @todo add code to actually sort the groups (or do it api side?)
      return null
    })
    criticalGroups.sort(this.compare);
    // console.log(criticalGroups)
    warnGroups.sort(this.compare);
    // console.log(warnGroups)
    alertGroups.sort(this.compare);
    // console.log(alertGroups)
    allGroups = ackWarnGroups.concat(criticalGroups, ackWarnGroups, warnGroups, alertGroups)
    // console.log(allGroups)

    return allGroups
  }

  render() {
    var { deviceGroups, classes } = this.props
    // var widgetId;
    // Object.keys(dashboardWidgets).forEach((id) => {
    //   if (dashboardWidgets[id].type === 'deviceNOC') {
    //     widgetId = id;
    //   }
    // })
    // if (widgetId && dashboardWidgets[widgetId]) {
    if (deviceGroups) {
      var orderedDeviceGroups = this.orderDeviceGroups(deviceGroups)
      return(
          <div >
            <Grid container spacing={1}  >
              {
                orderedDeviceGroups.map((group, index) => {
                  var status = group.alertStatus
                  return (
                    <Grid key={group.id} item xs={3} lg={2} xl={2}>
                      <Link style={{textDecoration: "none"}} to={"/devices?search_term=" + group.name }>
                        <Paper onClick={() => this.props.handleClick(group.id) } className={`${classes.root} ${classes[status]}`}>
                          <Typography variant="body1" noWrap={true}>
                            <AlertsIcon status={status}/> {group.name}
                          </Typography>
                          <Grid container alignItems="center" className={'iconbar'}>
                            <Grid item xs={3}>
                              <Typography variant="body1" noWrap={true}>
                                <FolderOpenIcon/> {group.total_sub_groups}
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography variant="body1" noWrap={true}>
                                <DevicesIcon/> {group.total_devices}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Link>
                    </Grid>
                  )
                })
              }
            </Grid>
          </div>
      )
    } else {
      return null;
    }
  }
}

export default connect()(withStyles(styles)(AlertOverviewWidget))
