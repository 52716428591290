import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Auth } from 'aws-amplify';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { fetchJWT } from '../actions/session'

class AWSLoginContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      account_id: '',
      error_msg: ''
    }
  }

  setTitle(title) {
    this.setState({ title: title });
  }

  componentDidMount() {
    this.props.setParentTitle('Login Page')
  }

  setUsername (event) {
    // console.log(text)
    this.setState({ username: event.target.value })
  }

  setPassword (event) {
    // console.log(text)
    this.setState({ password: event.target.value })
  }

  setAccountID (event) {
    // console.log(text)
    this.setState({ account_id: event.target.value })
  }

  async handleButtonClick() {
    try {
        await Auth.signIn(this.state.username, this.state.password);
    } catch (error) {
        console.log('error signing in', error);
    }
  }


  render() {
    // const { } = this.props
      return(
          <form>
            <TextField
              autoFocus
              margin="dense"
              id="username"
              label="username"
              type="text"
              fullWidth
              variant="outlined"
              autoComplete="username"
              onChange={(event) => this.setUsername(event)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="password"
              label="Password"
              type="password"
              fullWidth
              variant="outlined"
              autoComplete="current-password"
              onChange={(event) => this.setPassword(event)}
            />
            <Button onClick={() => this.handleButtonClick()} variant="outlined" color="primary">
              Login
            </Button>
            <Typography>{this.props.login_error_msg}</Typography>
          </form>
      )
  }
}

function mapStateToProps(state) {
  const { login_error_msg } = state.userSession;
  return {
    login_error_msg
  }
}



export default connect(mapStateToProps, { fetchJWT } )(AWSLoginContainer)
