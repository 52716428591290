import React, { useEffect } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';

import { epochToTimestamp } from '../libs/utils'
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';

import AlertsIcon from  '../components/AlertsIcon'
import { getAlertInformation } from '../actions/alerts';
import { acknowledgeAlert } from '../actions/alerts'

const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    alertContainer: {
        width: '95%',
        display: 'flex',
    },
    card: {
      width: '100%'
    },
    table: {
      width: '100%',
    },
    tableWrapper: {
      overflowX: 'auto',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    row: {
      cursor: 'pointer'
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default function AlertRow({row, state, setAlertRow}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const thisalert = useSelector(state => state.alerts.byId[row.id]);
    const [open, setOpen] = React.useState(true);
    const [tab, setTab] = React.useState("summary");
    const [message, setMessage] = React.useState(null);
    const devicesById = useSelector(state => state.devices.byId)
    var groups = []

    row.monitor_object_groups.forEach((value, index) => {
        groups.push(value.name)
    })

    useEffect(() => {
        if (state) {
          setOpen(state);
        }
      }, [state, thisalert])

    const handleClick = (row) => {
        dispatch(getAlertInformation(row.id))
        setOpen(!open)
        console.log(row)
    }

    const radioChange = (e) => {
        setTab(e.target.value)
    }

    return (
        <React.Fragment>
            <TableRow
            hover
            onClick={event => handleClick(row)}
            role="checkbox"
            tabIndex={-1}
            key={row.id}
            className={classes.row}
            >
                <TableCell>
                    <AlertsIcon needsColour status={row.rule}/> {row.rule}
                </TableCell>
                <TableCell >
                    {epochToTimestamp(row.startEpoch)}
                </TableCell>
                <TableCell>{devicesById[row.monitorObjectId]['request-id']}</TableCell>
                <TableCell>
                    {devicesById[row.monitorObjectId]['Customer Name']}
                </TableCell>
                <TableCell >
                    {devicesById[row.monitorObjectId]['postcode']}
                </TableCell>
                <TableCell>
                    {devicesById[row.monitorObjectId]['circuit reference']}
                </TableCell>
                <TableCell>{row.instanceName}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={7} padding="none" hidden={open}>
                    <Collapse in={!open} style={{width: "100%"}}>
                        <Card>
                            <CardContent>
                                <RadioGroup row aria-label="position" name="position" defaultValue={tab} onChange={radioChange} style={{paddingBottom:"10px"}}>
                                    <FormControlLabel
                                    value={'summary'}
                                    control={<Radio color="primary" />}
                                    label="Summary"
                                    labelPlacement="start"
                                    />
                                    <FormControlLabel
                                    value={'info'}
                                    control={<Radio color="primary" />}
                                    label="Information"
                                    labelPlacement="start"
                                    />
                                    <FormControlLabel
                                    value={'acknowledge'}
                                    control={<Radio color="primary" />}
                                    label="Acknowledge"
                                    labelPlacement="start"
                                    />
                                </RadioGroup>
                            {
                            (tab === 'summary') ?
                                <Grid container spacing={3}>
                                    <Grid item>
                                        <Typography className={classes.title} color="primary" style={{marginBottom: '10px'}}>
                                            {row.message ? row.message.subject : 'No message in alert.'}
                                        </Typography>
                                        <ul>
                                        {row.message ?

                                            row.message.body.replace(". ", "\n").replace('\n\n', '\n').split('\n').map((line, index) => {
                                                if(line.length !== 0) {
                                                    return (
                                                        <li>
                                                            <Typography variant="h5" component="h2" style={{paddingBottom:"5px"}}>
                                                                {line}
                                                            </Typography>
                                                        </li>
                                                    )
                                                } else return null
                                            })

                                        :
                                            <li>
                                                <Typography variant="h5" component="h2">
                                                    No message in alert.
                                                </Typography>
                                            </li>
                                        }
                                        </ul>
                                    </Grid>
                                </Grid>
                            : (tab === 'info') ? (
                                <TableContainer>
                                    <Table size="small" aria-label="recipients table">
                                        <TableHead>
                                        <TableRow>
                                            <TableCell>ID</TableCell>
                                            <TableCell>Threshold</TableCell>
                                            <TableCell>Value</TableCell>
                                            <TableCell>DataSource</TableCell>
                                            <TableCell>Datapoint</TableCell>
                                            <TableCell>Groups</TableCell>
                                            <TableCell>Escalation</TableCell>
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        <TableRow>
                                            <TableCell>
                                            {row.id}
                                            </TableCell>
                                            <TableCell>
                                            {row.threshold}
                                            </TableCell>
                                            <TableCell>
                                            {row.alertValue}
                                            </TableCell>
                                            <TableCell>
                                            {row.resourceTemplateName}
                                            </TableCell>
                                            <TableCell>
                                            {row.dataPointName}
                                            </TableCell>
                                            <TableCell>
                                            {groups.join(", ")}
                                            </TableCell>
                                            <TableCell>
                                            {row.chain}
                                            </TableCell>
                                        </TableRow>
                                        </TableBody>
                                    </Table>
                                    </TableContainer>
                            ) :
                                <Grid container justify="space-between" spacing={2}>
                                  <Grid item>
                                    <FormControl fullWidth className={classes.margin} variant="outlined" style={{height: '100px'}}>
                                      <InputLabel htmlFor="outlined-adornment-amount">Acknowledgement Comment</InputLabel>
                                      <OutlinedInput
                                        id="outlined-adornment-amount"
                                        value={null}
                                        onChange={(e) => setMessage(e.target.value)}
                                        startAdornment={<InputAdornment position="end"></InputAdornment>}
                                        labelWidth={180}
                                        multiline
                                        placeholder="<FULL NAME> - <DESCRIPTION>"
                                        style={{height: '100px', width: '80%'}}
                                      />
                                    </FormControl>
                                    <Button
                                      onClick={() => dispatch(acknowledgeAlert(row.id, message))}
                                      color="primary"
                                      fullWidth variant="contained"
                                      style={{width:'150px',top:'25px',height:'50px',right:'-40px'}}
                                    >
                                      Acknowledge
                                    </Button>
                                  </Grid>
                                </Grid>
                            }
                            </CardContent>
                        </Card>
                    </Collapse>
                </TableCell>
            </TableRow>


        </React.Fragment>
      );


}
