import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Line } from 'react-chartjs-2';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import NoteIcon from '@material-ui/icons/Note';
import { withStyles, createStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  MuiPickersUtilsProvider,
  DateTimePicker
} from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import getUnixTime from 'date-fns/getUnixTime'
import SubmitTicket from '../components/dialogs/SubmitTicket'
import CircularIntegration from '../components/CircularIntegration'
import PageRefreshInput from '../components/PageRefreshInput'

import { initRequestDashboardGraphs, initRequestWidgetData, getDashboardFromReportId } from '../actions/dashboard'

// import { data } from '../data/graphs'




const styles = (theme) =>
  createStyles({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 400,
    },
    root: {
      paddingTop: '40px',
      flexGrow: 1
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary
    }
  });


class GraphsContainer extends Component {
  abortController = new AbortController()

  constructor(props) {
    super(props);
    var today = new Date();
    var yesterday = new Date(new Date().setDate(new Date().getDate()-1));
    this.state = {
      is_hidden: true,
      dialog_is_hidden: true,
      loadingWidgets: true,
      rangesChanged: false,
      report_error: false,
      fromDate: yesterday,
      fromEpoch: getUnixTime(yesterday),
      toDate: today,
      toEpoch: getUnixTime(today),
      dashboard_id: props.Device.devicedashboardid || props.Device.rmdashid
    }
    this.timer = null;
  }

  componentDidMount() {
    this.setState({ loadingWidgets: true })
    // this.props.setParentTitle('Device Info')
    var dashboard_id = this.props.Device.devicedashboardid || this.props.Device.rmdashid;
    if (dashboard_id) {
      // this.getDashboard(this.props.Device.device_report_id)
      this.setState()
      this.updateGraphRanges();
    } else {
      this.setState({ loadingWidgets: false, report_error: true })
    }
    this.setState({ rangesChanged: false })
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  async getDashboard (device_report_id) {
    const Report = await this.props.getDashboardFromReportId(device_report_id, { signal: this.abortController.signal })
    this.setState({
      dashboard_id: Report.payload.id,
      loadingWidgets: false
    })
    this.updateGraphRanges();
    console.log(Report)
  }

  setDateRangeValues() {
    var today = new Date();
    var yesterday = new Date(new Date().setDate(new Date().getDate()-1));

    this.handleFromDateChange(yesterday)
    this.handleToDateChange(today)
  }

  handleFromDateChange = date => {
    var hasChanged = false;
    if (this.state.fromEpoch !== getUnixTime(date)) {
        hasChanged = true;
    }
    this.setState({
      rangesChanged: hasChanged,
      fromDate: date,
      fromEpoch: getUnixTime(date)
    });
  };

  handleToDateChange = date => {
    var hasChanged = false;
    if (this.state.fromEpoch !== getUnixTime(date)) {
        hasChanged = true;
    }
    this.setState({
      rangesChanged: hasChanged,
      toDate: date,
      toEpoch: getUnixTime(date)
    });
  };

  handleTicketButtonClick = (bool) => {
    this.setState({ dialog_is_hidden: bool })
  }

  async updateGraphRanges (dashboard_id) {
    const range = {
      from: this.state.fromEpoch,
      to: this.state.toEpoch
    }
    this.setState({ loadingWidgets: true })
    var response = await this.props.initRequestDashboardGraphs(this.state.dashboard_id, range, { signal: this.abortController.signal })
    if (response.error) {
      this.setState({ loadingWidgets: false, rangesChanged: true, report_error: true })
    } else {
      this.setState({ loadingWidgets: false, rangesChanged: false })
    }
  }

  render() {
    const { graphs, classes } = this.props;
    var widgets = graphs; // just to check it works, if so rename all widgets to graphs

    if (this.state.report_error) {
      return (
        <Paper justify="center" style={{ 'marginTop': '20px'}}>
          <Typography color="error" gutterBottom>
            This device does not have an associated dashboard. Contact support.
          </Typography>
        </Paper>
      )
    }

    return(
        <div className={classes.root}>
          <SubmitTicket state={!this.state.dialog_is_hidden} device={this.props.Device} callbackFunction={(state) => this.handleTicketButtonClick(state)}/>
          <div className="row">
            <Grid container spacing={2} >
              <Grid item xs={6} sm={6} md={3} lg={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    id="graphs_from_datepicker"
                    label="From Date/Time"
                    value={this.state.fromDate}
                    onChange={(date) => this.handleFromDateChange(date)}
                    fullWidth
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    id="graphs_to_datepicker"
                    label="To Date/Time"
                    value={this.state.toDate}
                    onChange={(date) => this.handleToDateChange(date)}
                    fullWidth
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={4} sm={4} md={1} lg={1}>
                <CircularIntegration text="Update" is_loading={this.state.loadingWidgets} onClick={() => this.updateGraphRanges()}/>
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={3} >
                <PageRefreshInput receivedAt={this.props.receivedAt} disabled={this.state.rangesChanged} refreshFunction={() => this.updateGraphRanges()}/>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2}>
                <Button startIcon={<NoteIcon/>} fullWidth variant="contained" color="primary" onClick={() => this.handleTicketButtonClick(false)}>Raise Ticket</Button>
              </Grid>
            </Grid>
            <Grid container spacing={2} justify="center" >
              {
                this.state.loadingWidgets ?
                  <CircularProgress className={classes.progress} />
                :

                Object.keys(widgets).map(function(id, keyIndex) {
                  if (widgets[id].type === 'graph') {
                    var graph = widgets[id]
                    var data = { datasets: [] };
                    var options = {
                      title: { text: graph.title , display: true },
                      maintainAspectRatio: true,
                      scales: {
                        xAxes: [{
                          ticks: {
                            autoSkip: true,
                            maxTicksLimit: 5
                          },
                          type: 'time',
                          // distribution: 'linear'
                        }],
                        yAxes: [{
                          scaleLabel: {
                            display: true,
                            labelString: graph.verticalLabel
                          }
                        }]
                      },
                      tooltips: {
                        mode: 'index',
                        intersect: false
                      },
                      hover: {
                        mode: 'index',
                        intersect: false
                      },
                      legend: {
                        display: false
                      }
                    }

                    if (graph.lines) {
                      graph.lines.map(function(line, index) {
                        var data_points = [];
                        graph.lines[index].data.map(function (value, line_index) {
                          data_points.push({ x: graph.timestamps[line_index], y: line.data[line_index] })
                          return null
                        })

                        var color = hexAToRGBA('#' + graph.lines[index].color)
                        data.datasets.push({
                          data: data_points,
                          label: graph.lines[index].legend,
                          backgroundColor: color,
                          borderColor: color,
                          fill: false,
                          borderWidth: 1,
                          pointRadius: 0

                        })
                        return null
                      })
                    }
                    // style={{position: 'relative', height: '30vh', width: '40vw'}}
                    return (
                      <Grid key={keyIndex} item xs={12} sm={12} md={6} lg={6} >
                        <Paper className={classes.root}>
                          <div >
                            <Line data={data} options={options} />
                          </div>
                        </Paper>
                      </Grid>
                    );
                  }
                return null
                })
              }
            </Grid>
          </div>
        </div>
      )
  }

}

function hexAToRGBA(h) {
  let r = 0, g = 0, b = 0, a = 1;

  if (h.length === 5) {
    r = "0x" + h[1] + h[1];
    g = "0x" + h[2] + h[2];
    b = "0x" + h[3] + h[3];
    a = "0x" + h[4] + h[4];

  } else if (h.length === 7) {
    r = "0x" + h[1] + h[2];
    g = "0x" + h[3] + h[4];
    b = "0x" + h[5] + h[6];
    // a = "0x" + h[7] + h[8];
  }
  // a = +(a / 255).toFixed(3);

  return "rgba(" + +r + "," + +g + "," + +b + "," + a + ")";
}

function mapStateToProps(state) {
  const { graphs } = state.dashboard

  return { graphs }
}

const mapDispatchToProps = {
  initRequestDashboardGraphs,
  initRequestWidgetData,
  getDashboardFromReportId
}

const enhance = compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(GraphsContainer)
