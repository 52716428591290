import React, { Component } from 'react'
import { connect } from 'react-redux'

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import { initRequestSession, fetchJWT, logoutUser} from '../actions/session';
import { initRequestAlerts } from '../actions/alerts'
// import {  getDashboardFromReportId } from '../actions/dashboard';
import { initDeviceSearch } from '../actions/devices';
import { initRequestAllDeviceGroups } from '../actions/devicegroup'
import { initRequestReports } from '../actions/reports'
import AppContainer from  './AppContainer'

class LoadingContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    }
  }

  setTitle(title) {
    this.setState({ title: title });
  }

  componentDidMount() {
    this.initialDataLoad()
  }

  async initialDataLoad() {
    this.setState({ loading: true })

    // const session = await this.props.initRequestSession()
    // if (session.type && session.type !== 'SESSION_FAILURE') {
    // this.props.dispatch(initRequestDashboard(dash_id))
    // this.props.initRequestAllDeviceGroups()
    // this.setState({ wan_group_dash_id: session.payload.wan_group_dash_id })
      // this.checkExistingSession()

      // Switching back to wan view dash...
      await this.props.initDeviceSearch()
      await this.props.initRequestAlerts()
      await this.props.initRequestReports()

    //   this.setState({ is_offline: false, logged_in: true })
    // } else {
    //   this.setState({ logged_in: false })
    // }
    this.setState({ loading: false })
  }

  handleButtonClick () {

    this.props.fetchJWT(this.state.username, this.state.password, this.state.account_id)

  }

  render() {
    // const { } = this.props
    if (this.state.loading === true) {
      return(
        <Grid container justify="center" style={{ paddingTop: '200px' }}>
          <CircularProgress size={24}  />
          <Typography>Loading data..</Typography>
        </Grid>
      )
    } else {
      return(
        <AppContainer/>
      )
    }
  }
}

function mapStateToProps(state) {
  const { login_error_msg } = state.userSession;
  return {
    login_error_msg
  }
}

const mapDispatchToProps = {
  initRequestSession,
  fetchJWT,
  logoutUser,
  initRequestAllDeviceGroups,
  initDeviceSearch,
  initRequestAlerts,
  initRequestReports
}



export default connect(mapStateToProps, mapDispatchToProps)(LoadingContainer)
