export function devices(state = { byId: null, loading: false }, action) {

  switch (action.type) {
    case 'RECEIVE_DEVICE':
      return Object.assign({}, state, {
        loading: false,
        byId: action.payload
        // byId: action.payload.data.items
    })
    case 'REQUEST_DEVICE':
      return Object.assign({}, state, {
        // byId: [],
        loading: true
    })
    case 'DEVICE_SEARCH_SUCCESS':
      return Object.assign({}, state, {
        // byId: keyDevicesById(action.payload),
        byId: !state.byId ? keyDevicesById(action.payload) : { ...state.byId, ...keyDevicesById(action.payload) }
    })
    default:
        return state
    }
}


export function deviceSearch(state = {
  deviceIds: null,
  search_term: null,
  api_failure: false,
  loading: false
}, action) {
  switch (action.type) {
    case 'DEVICE_SEARCH_RESET':
      return Object.assign({}, state, {
        loading: false,
        search_term: null,
        deviceIds: [],
      })
    case 'DEVICE_SEARCH_REQUEST':
      return Object.assign({}, state, {
        search_term: action.meta ? action.meta.search_term : ""
      })
    case 'DEVICE_SEARCH_SUCCESS':
      return Object.assign({}, state, {
        loading: false,
        Devices: action.payload,
        deviceIds: deviceIds(action.payload),
        instanceToDeviceIds: mapInstanceIdsToDeviceIds(action.payload),
        receivedAt: Math.floor(new Date() / 1000),
        api_failure: false,
      })
    case 'DEVICE_SEARCH_FAILURE':
      return Object.assign({}, state, {
        api_failure: true,
        loading: false
    })
    default:
        return state
    }
}

function deviceIds(Devices) {
  var ids = [];
  Devices.forEach((Device) => {
    ids.push(Device.id)
  })
  return ids
}

function keyDevicesById(Devices) {
  var mappings = {};
  Devices.forEach((Device) => {
    mappings[Device.id] = Device
  })
  return mappings
}

function mapInstanceIdsToDeviceIds(Devices) {
  var mappings = {};
  Devices.forEach((Device) => {
    if (Device.pri_instance_id) {
      mappings[Device.pri_instance_id] = Device.id
    }
  })
  return mappings
}
