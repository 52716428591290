export default function reports(state = {
  items: [],
  results: {
    availability: {
      deviceSearch: [],
      day: {
        timestamp: 0,
        results: []
      },
      week: {
        timestamp: 0,
        results: []
      },
      month: {
        timestamp: 0,
        results: []
      },
      year: {
        timestamp: 0,
        results: []
      },
      custom: {
        timestamp: 0,
        results: []
      }
    },
    circuitMetrics: {
      deviceSearch: [],
      day: {
        timestamp: 0,
        results: []
      },
      week: {
        timestamp: 0,
        results: []
      },
      month: {
        timestamp: 0,
        results: []
      },
      year: {
        timestamp: 0,
        results: []
      },
      custom: {
        timestamp: 0,
        results: []
      }
    }
  }
}, action) {
  switch (action.type) {
    case 'REPORTS_REQUEST':
      return Object.assign({}, state, {
      })
    case 'REPORTS_RECEIVE':
      return Object.assign({}, state, {
        items: action.payload
      })
    case 'REPORTS_FAILURE':
      return Object.assign({}, state, {
      })
    case 'REPORT_GROUPS_RECEIVE':
      return Object.assign({}, state, {
        groups: action.payload
      })
    case 'REPORT_EXECUTION_RECEIVE':
      if(action.payload === 'Unable to Load Result - Report too large')
        return
      else
        return Object.assign({}, state, {
          results: storeReport(action.payload, state.results)
        })
    default:
        return state
      }
}

function storeReport(result, obj) {
  var object, dp, dpData, i, j, y, row, measurementsData, device, reportTime, from, to, timeDiff, firstLine, datapoints, measurements;
  var array = []
  var data = []
  var results = result.split("\n");
  result = ""
  firstLine = results[5].split(",")

  from = results[0].split(",")
  from = from[from.length-1].split(" ")
  from = Date.parse(from[0]+"T"+from[1])

  to = results[1].split(",")
  to = to[to.length-1].split(" ")
  to = Date.parse(to[0]+"T"+to[1])

  timeDiff = (to-from)/86400000

  if(timeDiff === 1)
    reportTime = 'day'
  else if(timeDiff === 7)
    reportTime = 'week'
  else if(timeDiff === 30)
    reportTime = 'month'
  else if(timeDiff === 365)
    reportTime = 'year'
  else
    reportTime = 'custom'

  if(firstLine[1] === "InOctets" || firstLine === "OutOctets") {
    datapoints = ["InOctets", "OutOctets"]
    measurements = ["max", "average"]
  }
  else if(firstLine[1] === "PingLossPercent") {
    datapoints = ["PingLossPercent"]
    measurements = ["average"]
  }

  for(i=5; i<(results.length-1); i++) {
    row = results[i].split(",")
    measurementsData = {}
    for(j=0; j<measurements.length; j++) {
      measurementsData[measurements[j]] = row[2+j]
    }
    data.push({device:row[0], dummyName:measurementsData})
  }
  for(j=0; j<data.length; j+=datapoints.length) {
    object = {}
    device = data[j].device
    object.device = device
    for(i=0; i<datapoints.length; i++) {
      dpData = data[j+i].dummyName
      dp = datapoints[i]
      object[dp] = dpData
    }
    array.push(object)
  }

  if(firstLine[1] === "PingLossPercent") {
    obj.availability[reportTime].results = array
    obj.availability[reportTime].timestamp = to
  }
  else if (firstLine[1] === "InOctets" || firstLine === "OutOctets") {
    obj.circuitMetrics[reportTime].results = array
    obj.circuitMetrics[reportTime].timestamp = to
  }


  y = Object.assign({}, obj);

  return y
}
