import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';

import ConfigureMaintenance from '../components/dialogs/ConfigureMaintenance'

import { epochToTimestampWithYears } from '../libs/utils'


function desc(a, b, orderBy) {
  if (a[orderBy] === undefined) a[orderBy] = ''
  if (b[orderBy] === undefined) b[orderBy] = ''
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort, type } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  const headCells = [
        { id: 'start', numeric: false, disablePadding: false, label: 'Start' },
        { id: 'end', numeric: false, disablePadding: false, label: 'End' },
        { id: 'duration', numeric: false, disablePadding: false, label: 'Duration' }
    ]

  if(type === 'DeviceSDT') {
    headCells.push(
        { id: 'device', numeric: false, disablePadding: false, label: 'Device' }
    );
  }
  else if(type === 'DeviceGroupSDT') {
    headCells.push(
        { id: 'group', numeric: false, disablePadding: false, label: 'Full Path' }
    );
  }
  else if(type === 'DeviceDataSourceSDT') {
    headCells.push(
        { id: 'device', numeric: false, disablePadding: false, label: 'Device' },
        { id: 'datasource', numeric: false, disablePadding: false, label: 'DataSource' }
    );
  }
  else if(type === 'CollectorSDT') {
    headCells.push(
        { id: 'collector', numeric: false, disablePadding: false, label: 'Collector' }
    );
  }
  else if(type === '' || type === null) {
    headCells.push(
        { id: 'type', numeric: false, disablePadding: false, label: 'Type' },
        { id: 'name', numeric: false, disablePadding: false, label: 'Applied At' }
    );
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'null' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const propDict = {
    DeviceSDT: "deviceDisplayName",
    DeviceGroupSDT: "deviceGroupFullPath",
    DeviceDataSourceSDT: "deviceDisplayName",
    CollectorSDT: "collectorDescription",
  }

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    width: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  row: {
    cursor: 'pointer'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function MaintenanceTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('start_date');
  const [selected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [dialogState, setDialogState] = React.useState(false);
  const [appliedAt, setTargetName] = React.useState(null)
  const [sdtRow, setSDTRow] = React.useState({
    id: "-",
    start: "-",
    end: "-",
    duration: "-",
    type: "-",
    appliedAt: "-",
    admin: "-",
    timezone: "-"
  });
  const { type } = props
  var rows = props.items || [];

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleClick(row) {
    setSDTRow(row)
    setTargetName(row[propDict[row.type]])
    setDialogState(true)
  }

  function closeDialog() {
    setDialogState(false)
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <ConfigureMaintenance row={sdtRow} state={dialogState} name={appliedAt} closeDialog={closeDialog}/>
        <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size='small'
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              type={type}
            />
            <TableBody>
              {
                stableSort(rows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  console.log(row)
                  return (

                    <TableRow
                      hover
                      onClick={event => handleClick(row)}
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                      className={classes.row}
                    >
                      <TableCell>
                          {epochToTimestampWithYears(row.startDateTime)}
                      </TableCell>
                      <TableCell>
                          {epochToTimestampWithYears(row.endDateTime)}
                      </TableCell>
                      <TableCell>
                          {row.duration > 1440 ? (parseInt(row.duration/1440) + " Days") : ((row.duration/60).toFixed(1) + " Hours")}
                      </TableCell>
                        {
                          type === null ? (
                              <React.Fragment>
                                  <TableCell>
                                      {row.type}
                                  </TableCell>
                                  <TableCell >
                                      {row[propDict[row.type]]}
                                  </TableCell>
                              </React.Fragment>
                          ) : (
                              <React.Fragment>
                                  <TableCell >
                                      {row[propDict[type]]}
                                  </TableCell>
                                  {
                                      row.type === 'DeviceDataSourceSDT'
                                      ?
                                      <TableCell>{row.dataSourceName}</TableCell>
                                      :
                                      null
                                  }
                              </React.Fragment>
                          )
                        }
                        </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
