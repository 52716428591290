import Select from 'react-select';
import React from 'react';
import makeAnimated from "react-select/animated";

import { makeStyles } from '@material-ui/core/styles';

const animatedComponents = makeAnimated();

const styles = makeStyles((theme) => ({
  root: {
    width: '600px',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function AsyncDropDown(props) {
  const open = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;
  const classes = styles();
  const { devices, callbackFromParent } = props
  var names = [], i, obj;
  for(i=0; i<devices.length; i++) {
    obj = {value: devices[i].label, label: devices[i].label}
    names.push(obj)
  }
  //names.unshift({value: "all", label: "ALL DEVICES"})
  React.useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }
    (async () => {
      if (active) {
        setOptions(names);
      }
    })();
    return () => {
      active = false;
    };
  }, [loading, devices.authStore, names]);
  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);
  const handleChange = e => {
    //setSelectedValue(e[0].value);
    var devNames = []

    try {
      for(var i=0; i<e.length; i++) {
        devNames.push(e[i].value)
      }
    }
    catch {}
    finally {
      callbackFromParent(devNames);
    }

  }
  return (
    <div className={classes.root}>
      <Select
      defaultValue={[]}
      isMulti
      name="colors"
      components={animatedComponents}
      options={names}
      className="basic-multi-select"
      classNamePrefix="select"
      styles={{width: '500px'}}
      onChange={handleChange}
      autoBlur={false}
      closeMenuOnSelect={false}
      />
    </div>
  );
}
