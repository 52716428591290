import React from 'react';
import { useDispatch, useSelector } from "react-redux";
// import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import AlertMethodIcon from '../AlertMethodIcon'
import {
  initRequestRecipientGroupById, initAddRecipient,
  initRemoveRecipient, initSaveRecipients
} from '../../actions/alerts'

const styles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  }
}));

export default function ConfigureRecipients({ account_id }) {
  const [open, setOpen] = React.useState(false);
  const [emailInput, setEmail] = React.useState("");
  const recipient_group = useSelector(state => state.alerts.recipient_group);
  const dispatch = useDispatch();
  const classes = styles();

  const setRecipientEmail = (event) => {
    console.log(event.target.value)
    setEmail(event.target.value)
  }

  const isValidEmailAddress = (address) => {
    return !! address.match(/.+@.+/);
  }

  const addRecipient = async () => {
    // var recipient_body = recipient_group
    if (isValidEmailAddress(emailInput)) {
      var Recipient = {
          "type": "ARBITRARY",
      		"method": "EMAIL",
      		"addr": emailInput,
      		"contact": ""
      }
      // recipient_body.recipients.push({
      //   "type": "ARBITRARY",
    	// 	"method": "EMAIL",
    	// 	"addr": emailInput,
    	// 	"contact": ""
      // })
      // console.log(recipient_body)
      const something = await dispatch(initAddRecipient(Recipient))
      console.log(something)
      dispatch(initSaveRecipients(recipient_group));
    } else {
      console.log('email address is not valid')
    }
  };

  const removeRecipient = async (recipient_to_remove) => {
    await dispatch(initRemoveRecipient(recipient_to_remove));
    dispatch(initSaveRecipients(recipient_group));
  };

  // const saveRecipients = () => {
  //   console.log(recipient_group)
  //   dispatch(initSaveRecipients(recipient_group));
  // }

  const handleClickOpen = () => {
    if (!recipient_group) {
      dispatch(initRequestRecipientGroupById(account_id))
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className={classes.root}>
      <Fab
        variant="extended"
        color="primary"
        // className={buttonClassname}
        // disabled={is_loading}
        onClick={handleClickOpen}
        className={classes.fab}
      >
        Configure Alerts
      </Fab>
      </div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Alerts</DialogTitle>
        <DialogContent>
          <TableContainer style={{"marginBottom": "20px"}} component={Paper}>
            <Table size="small" aria-label="recipients table">
              <TableHead>
                <TableRow>
                  <TableCell>Method</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Remove</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!recipient_group || recipient_group.recipients.length === 0 ?
                  <TableRow>
                    <TableCell>
                      No email addresses configured.
                    </TableCell>
                  </TableRow>
                :
                recipient_group.recipients.map((item) =>
                  <TableRow key={item.addr}>
                    <TableCell><AlertMethodIcon type={item.method}/></TableCell>
                    <TableCell>{item.addr}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => removeRecipient(item)} aria-label="delete">
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              }
              </TableBody>
            </Table>
          </TableContainer>
          <TextField
            error={!isValidEmailAddress(emailInput) && emailInput.length > 3}
            autoFocus
            margin="dense"
            id="name"
            label="Add Recipient Email"
            type="email"
            fullWidth
            variant="outlined"
            onChange={setRecipientEmail}

          />
          <Button onClick={addRecipient} variant="outlined" color="primary">
            Add
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          { /*
          <Button onClick={saveRecipients} color="primary">
            Save
          </Button>
          */ }
        </DialogActions>
      </Dialog>
    </div>
  );
}
