import { Link } from 'react-router-dom';
import Fab from '@material-ui/core/Fab';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';


const styles = makeStyles(theme => ({
fab: {
  position: 'absolute',
  top: theme.spacing(5),
  left: theme.spacing(2)
  }
}))

export default function BackButton({link}) {
  const classes = styles()
  return(
        <div>
        <Link to={link}>
          <Fab
          variant="extended"
          color="primary"
          className={classes.fab}
          >
          Back
        </Fab>
      </Link>
        </div>
  )
}
