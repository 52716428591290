/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:1f7f7849-6210-47e9-8cd5-97e953ed81f9",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_1SQQDukNJ",
    "aws_user_pools_web_client_id": "753289d7bd400l15r39svhmdr7",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://jwrzbpg2pbaj3mjvs67mvh67qm.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
