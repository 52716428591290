import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { getUnixTime } from 'date-fns';
import { updateSDT, removeSDT } from '../../actions/maintenance';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  }
}));


export default function ConfigureMaintenance({ row, state, closeDialog, name }) {
  const [open, setOpen] = React.useState(state);
  const classes = useStyles();
  const [currentSDT, setSDT] = React.useState(row)
  const [startTime, setStartTime] = React.useState(null)
  const [endTime, setEndTime] = React.useState(null)
  const [isChanged, setChanged] = React.useState(false)
  const [initialStart, setInitialStart] = React.useState(null)
  const [initialEnd, setInitialEnd] = React.useState(null)
  const deviceArray = useSelector(state => state.deviceSearch.Devices)
  const groupArray = useSelector(state => state.deviceGroups.items) 
  const dispatch = useDispatch();

  useEffect(() => {
    if (state) {
      setOpen(state);
      setSDT(row);
      setStartTime(formatTime(row.startDateTimeOnLocal))
      setEndTime(formatTime(row.endDateTimeOnLocal))
      setInitialStart(formatTime(row.startDateTimeOnLocal))
      setInitialEnd(formatTime(row.endDateTimeOnLocal))
    }
  }, [state, row])

  const formatTime = (time) => {
    return time.split(" ")[0] + "T" + time.split(" ")[1]
  }

  const changeStartDate = (val) => {
    setStartTime(val)
    val += ':00'
    if(val === initialStart && endTime === initialEnd)
      setChanged(false)
    else
      setChanged(true)
  }

  const changeEndDate = (val) => {
    setEndTime(val)
    val += ':00'
    if(val === initialEnd && startTime === initialStart)
      setChanged(false)
    else
      setChanged(true)
  }

  const handleClose = () => {
    setOpen(false);
    closeDialog();
    setSDT({
        id: "-",
        start: "-",
        end: "-",
        duration: "-",
        type: "-",
        appliedAt: "-",
        admin: "-",
        timezone: "-"
    })
  };

  const submitChange = () => {
    var id, arrayToSearch, identifier;
    var start = getUnixTime(new Date(endTime)) * 1000
    var end = getUnixTime(new Date(endTime)) * 1000

    if(row.type === 'DeviceSDT') {
      arrayToSearch = deviceArray
      identifier = 'label'
    } else if (row.type === 'DeviceGroupSDT') {
      arrayToSearch = groupArray
      identifier = 'name'
    }
    for(var x=0; x<arrayToSearch.length; x++) {
      if(arrayToSearch[x][identifier] === name) {
        id = arrayToSearch[x].id
      }
    }
    console.log()
    dispatch(updateSDT(id, start, end, "this is a test", row.type))
  }


  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Maintenance Summary: {name}</DialogTitle>
        <DialogContent>
          <div className={classes.root}>
                <Grid container spacing={1}  >
                  <Grid item>
                  <Card className={classes.root}>
                    <CardContent>
                      <Typography className={classes.title} color="primary" style={{marginBottom: '20px'}}>
                        Description
                      </Typography>
                      <Typography variant="h5" component="h2" style={{marginBottom: '10px'}}>
                        {currentSDT.admin ? currentSDT.admin : 'no message in alert'}
                      </Typography>
                      <Typography variant="body2" component="p">
                        {currentSDT.comment ? currentSDT.comment : 'No comment left by admin.'}
                      </Typography>
                    </CardContent>
                  </Card>
                  </Grid>
                  <Grid item>
                  <Table size="small" aria-label="recipients table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Start</TableCell>
                        <TableCell>End</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <form className={classes.container} noValidate>
                            <TextField
                              id="datetime-start"
                              label="Start Time"
                              type="datetime-local"
                              defaultValue={startTime}
                              className={classes.textField}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e) => changeStartDate(e.target.value)}
                            />
                          </form>
                        </TableCell>
                        <TableCell>
                          <form className={classes.container} noValidate>
                            <TextField
                              id="datetime-end"
                              label="End Time"
                              type="datetime-local"
                              defaultValue={endTime}
                              className={classes.textField}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e) => changeEndDate(e.target.value)}
                            />
                          </form>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item>
                    <Button
                      onClick={() => dispatch(removeSDT(row.id))}
                      color="primary"
                      variant="contained"
                      style={{marginTop: '10px'}}
                      disabled={isChanged}
                    >
                      Remove SDT
                    </Button>
                  </Grid>
                  <Grid item>         
                    <Button
                        onClick={submitChange}
                        color="primary"
                        variant="contained"
                        style={{marginTop: '10px'}}
                        disabled={!isChanged}
                      >
                        Submit Change
                    </Button>
                  </Grid>
                </Grid>

          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
