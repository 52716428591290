import { RSAA } from 'redux-api-middleware';
import { APIURL } from '../constants'

export const SDT_REQUEST = 'SDT_REQUEST'
export const SDT_SUCCESS = 'SDT_SUCCESS'
export const SDT_FAILURE = 'SDT_FAILURE'

export function initRequestSDTs() {
  var url = APIURL + '/api/v1/sdt'
  return {
    [RSAA]: {
      endpoint: url,
      method: 'GET',
      types: [
        {
          type: SDT_REQUEST
        },
        SDT_SUCCESS, SDT_FAILURE
      ],
      headers: {

      }
    }
  }
}

export const ADD_SDT_REQUEST = 'ADD_SDT_REQUEST'
export const ADD_SDT_SUCCESS = 'ADD_SDT_SUCCESS'
export const ADD_SDT_FAILURE = 'ADD_SDT_FAILURE'

export function initAddSDT(start, end, comment, type, sdtProp, id) {
  var params = '?startEpoch=' + start + '&endEpoch=' + end + '&comment=' + comment + '&type=' + type + '&prop=' + sdtProp 
  var url = APIURL + '/api/v1/sdt/devicesdt/' + id + params
  return {
    [RSAA]: {
      endpoint: url,
      method: 'POST',
      types: [
        {
          type: ADD_SDT_REQUEST
        },
        ADD_SDT_SUCCESS, ADD_SDT_FAILURE
      ],
      headers: {

      }
    }
  }
}

export const UPDATE_SDT_REQUEST = 'UPDATE_SDT_REQUEST'
export const UPDATE_SDT_SUCCESS = 'UPDATE_SDT_SUCCESS'
export const UPDATE_SDT_FAILURE = 'UPDATE_SDT_FAILURE'

export function updateSDT(id, start, end, comment, type) {
  var params = '?start=' + start + '&end=' + end + '&comment=' + comment + '&type=' + type 
  var url = APIURL + '/api/v1/sdt/updatesdt/' + id + params
  console.log(url)
  return {
    [RSAA]: {
      endpoint: url,
      method: 'PATCH',
      types: [
        {
          type: UPDATE_SDT_REQUEST
        },
        UPDATE_SDT_SUCCESS, UPDATE_SDT_FAILURE
      ],
      headers: {

      },
    }
  }
}

export const DELETE_SDT_REQUEST = 'DELETE_SDT_REQUEST'
export const DELETE_SDT_SUCCESS = 'DELETE_SDT_SUCCESS'
export const DELETE_SDT_FAILURE = 'DELETE_SDT_FAILURE'

export function removeSDT(id) {
  console.log(id)
  var url = APIURL + '/api/v1/sdt/removesdt/' + id
  return {
    [RSAA]: {
      endpoint: url,
      method: 'DELETE',
      types: [
        {
          type: DELETE_SDT_REQUEST
        },
        DELETE_SDT_SUCCESS, DELETE_SDT_FAILURE
      ],
      headers: {

      },
    }
  }
}